import "../styles/Loading.css";

interface ILoading {

}

const Loading: React.FunctionComponent<ILoading> = () => {
    return (
        <div className="loading-container">
            <div className="loading-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Loading;