import Input, { IInput } from "./Input";
import "../styles/InputWithButton.css"
import IconButton, { IIconButton } from "./IconButton";

type IInputWithButton = { inputprops: IInput, buttonProps: IIconButton };

export function InputWithButton(props: IInputWithButton) {
  return <div className="inputwithbutton-container" >
    <Input {...props.inputprops as IInput} inputClassName="input inputwithbutton-input" containerClassName="input-container inputwithbutton-input-container" readOnly/>
    <IconButton {...props.buttonProps as IIconButton} className="btn inputwithbutton-button" icon={props.buttonProps.icon} iconPosition="end" />
  </div>
}

InputWithButton.defaultProps = {
  buttonProps: {
    icon: "copy"
  }
} as IInputWithButton;