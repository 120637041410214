import NavbarSection from '../components/Navbar/NavbarSection';
import NavbarLink from '../components/Navbar/NavbarLink';

import List from '../components/Icons/List';
import Settings from '../components/Icons/Settings';
import Api from '../components/Icons/Api';
import User from '../components/Icons/User';
import DesignManager from '../components/Icons/DesignManager';

const NavbarItems = () => {
    return (<>
        <NavbarSection text="Hauptmenü">
            <NavbarLink to='/userprofile' icon={<User />} text="Benutzerkonto" />
            <NavbarLink to='/vacancies' permission='vacancies' icon={<List />} text="Offene Stellen" />
            <NavbarLink to='/defaultfields' permission='vacancies' icon={<List />} text="Standard Felder" />
            <NavbarLink to='/designmanager' permission='designmanager' icon={<DesignManager />} text="Design Manager" />
            <NavbarLink to='/apiconfiguration' permission='apiconfiguration' icon={<Api />} text="Api Manager" />
            <NavbarLink to='/users' permission='users' icon={<User />} text="Benutzer" />
            <NavbarLink to='/config' permission='config' icon={<Settings />} text="Konfiguration" />
        </NavbarSection>
    </>);
}

export default NavbarItems;