import { FORM_TYPE } from "../components/Form/FormInput";
import { IValidationState } from "../components/Form/ManagedForm";
import { HashPassword } from "../util/password";
import { Config } from "./Configuration";

type UserProfile = {
    UserName: string,
    EMail: string,
    OriginalPassword: string,
    Password: string,
    PasswordRepetition: string
}

export default function UserProfileView() {

    const passwordIsValid = (profile: UserProfile): IValidationState => {
        if ((!profile.Password && !profile.PasswordRepetition)) {
            return { "silent": { Key: "", MessageKey: "" } }
        }
        if (profile.Password !== profile.PasswordRepetition) {
            return { "PasswordRepetition": { Key: "Password", MessageKey: "Passwörter stimmen nicht überein" } }
        }
        return {};
    }

    const hashPassword = async (d: UserProfile) => {
        d.OriginalPassword = await HashPassword(d.OriginalPassword);
        d.Password = await HashPassword(d.Password);
        return d;
    }

    return <>
        <Config<UserProfile> Id="userProfile"
            SetConfigUrl="/config/setuserprofile"
            Source="/config/getuserprofile"
            Title="Benutzerkonto"
            Inputs={[
                {
                    Discriminator: FORM_TYPE.Text,
                    Id: "UserName",
                    Label: "Benutzername"
                },
                {
                    Discriminator: FORM_TYPE.Email,
                    Id: "EMail",
                    Label: "E-Mail"
                }
            ]} />

        <Config<UserProfile> Id="userProfile"
            SetConfigUrl="/config/setuserpassword"
            Source="/config/getuserprofile"
            Title="Passwort"
            IsValid={passwordIsValid}
            preSubmit={hashPassword}
            Inputs={[
                {
                    Discriminator: FORM_TYPE.Password,
                    Id: "OriginalPassword",
                    Label: "Aktuelles Passwort"
                },
                {
                    Discriminator: FORM_TYPE.Password,
                    Id: "Password",
                    Label: "Passwort"
                },
                {
                    Discriminator: FORM_TYPE.Password,
                    Id: "PasswordRepetition",
                    Label: "Passwort wiederholen",
                    Default: ""
                }
            ]} />
    </>
}