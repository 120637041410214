import { useRef } from "react";
import { ConnectDragSource, useDrag, useDrop } from "react-dnd";
import { Identifier } from "dnd-core";

interface IDraggableProps {
    dragItem: IDragItem;
    moveItem?: (dragIndex: number, hoverIndex: number, fieldId: number | string) => void;
    children: JSX.Element;
    itemType: string;
}

interface IDraggableTargetProps {
    moveRow: (dragIndex: number, hoverIndex: number, fieldId: number | string) => void;
    children: JSX.Element;
    dragItem?: IDragItem;
    drag?: ConnectDragSource;
    className?: string;
    acceptItemType: string;
}

interface IDragItem {
    id: number | string;
    index: number;
}

export const DropTarget = (props: IDraggableTargetProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<IDragItem, void, { handlerId: Identifier | null; }>(() => ({
        accept: props.acceptItemType,
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover: (item: IDragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.dragItem?.index ?? 0;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            props.moveRow(dragIndex, hoverIndex, item.id);

            item.index = hoverIndex;
        }
    }),[props.moveRow, props.dragItem]);

    if (props.drag)
        drop(props.drag(ref));
    else
        drop(ref)
    return (
        <div ref={ref} data-handler-id={handlerId} className={props.className}>
            {props.children}
        </div>
    );
};

export const Draggable = (props: IDraggableProps) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: props.itemType,
        item: { id: props.dragItem.id, index: props.dragItem.index },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        isDragging: (monitor) => { return props.dragItem.id === monitor.getItem().id }
    }));
    const opacity = isDragging ? 0.3 : 1;
    return (<div style={{ opacity }} key={props.dragItem.id}>
        {props.moveItem &&
            <DropTarget acceptItemType={props.itemType} dragItem={props.dragItem} moveRow={props.moveItem} drag={drag}>
                {props.children}
            </DropTarget>
        }
        {!props.moveItem &&
            <div ref={drag}>
                {props.children}
            </div>}
    </div>);
};
