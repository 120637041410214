import Button, { IButton } from "./Button";
import Icon, { Icons } from "./Icon";

type IconPosition = "start" | "end"

interface IIconButton extends IButton {
    icon: Icons;
    iconPosition?: IconPosition;
}

const IconButton: React.FunctionComponent<IIconButton> = (props) => {
    const { icon, iconPosition, ...buttonProps } = props;

    if (props.iconPosition === "start") {
        return (
            <Button {...buttonProps}>
                <Icon icon={props.icon} />
                {props.children}
            </Button>
        );
    }
    else {
        return (
            <Button  {...buttonProps} >
                {props.children}
                <Icon icon={props.icon} style={{ height: 20 }} />
            </Button>
        );
    }
}

IconButton.defaultProps = {
    iconPosition: "start"
}
export type { IIconButton };
export default IconButton;