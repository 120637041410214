
import Checkbox from "../Checkbox";
import { IFormInput } from "./FormInput";

interface IFormCheckboxInput extends IFormInput {
    Link?: string;
}

const FormCheckbox: React.FunctionComponent<IFormCheckboxInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.checked);
    };

    return (
        <Checkbox Link={props.Link} key={props.Id} checked={props.Value ?? props.Default} id={props.Id.toString()} label={props.Label} onChange={onChange} errorText={props.Validation?.MessageKey} />
    );
}

export type { IFormCheckboxInput }

export default FormCheckbox;