import "../styles/ErrorText.css";

interface IErrorText {
    text: string;
    className?: string;
}

const Text = (props: IErrorText) => {
    if (!props.text)
        return <></>

    return (
        <div className={"feedbacktext " + (props.className ? props.className : "invalid-text")}>
            {props.text}
        </div>
    );
}


export const ErrorText: React.FunctionComponent<IErrorText> = (props) => {
    return <Text text={props.text} className={props.className ? props.className : "invalid-text"} />
}

export const AccentedText = (props: IErrorText) => {
    return <Text text={props.text} className={props.className ? props.className : "accented-text"} />
}

