import { Dialog } from "@progress/kendo-react-dialogs";

import DataForm from "./DataForm";
import { IFormInput } from "./Form/FormInput";
import { IValidationState } from "./Form/ManagedForm";

interface IDataGridPopupForm<T> {
    Id: string,
    dataItem: T
    onSubmit: (dataItem: any) => void,
    onAbort: () => void,
    Inputs: IFormInput<T>[],
    validationState: IValidationState,
    onInputChange: (formInput: T) => void
}

function DataGridPopupForm<T>(props: IDataGridPopupForm<T>) {
    return (
        <Dialog onClose={props.onAbort}>
            <DataForm {...props}/>
        </Dialog>
    );
}

export type { IDataGridPopupForm }

export default DataGridPopupForm;