import React from "react";
import "../styles/Form.css";

interface IForm extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    
}
 
const Form: React.FunctionComponent<IForm> = (props) => {
    return (
        <form className="form" {...props}>
            {props.children}
        </form>
    );
}
 
export default Form;