const Api: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5px" height={20}>
            <defs></defs>
            <path d="M16.694,13.716A5.278,5.278,0,0,0,22.06,8.529a5.278,5.278,0,0,0-5.366-5.186,5.577,5.577,0,0,0-1.347.165A4.933,4.933,0,0,0,10.882.75a4.855,4.855,0,0,0-4.9,4.342,4.382,4.382,0,0,0-4.043,4.3,4.4,4.4,0,0,0,4.471,4.322Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <line x1="20.245" y1="23.25" x2="20.245" y2="16.716" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="18.938" y1="16.716" x2="21.551" y2="16.716" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="18.938" y1="23.25" x2="21.551" y2="23.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="11.097" y1="23.25" x2="11.097" y2="20.636" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
            <path d="M11.1,20.636h1.96a1.96,1.96,0,1,0,0-3.92H11.1Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M1.949,23.25l.737-4.919a1.9,1.9,0,0,1,3.752,0l.738,4.919" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <line x1="2.292" y1="20.963" x2="6.833" y2="20.963" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
        </svg>
    );
}

export default Api;