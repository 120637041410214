const User: React.FunctionComponent = () => {
    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" height={20}>
            <path d="M3.375 7.875 A4.125 4.125 0 1 0 11.625 7.875 A4.125 4.125 0 1 0 3.375 7.875 Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M.75,20.25a6.75,6.75,0,0,1,13.5,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M14.251,4.416a4.125,4.125,0,1,1-.006,6.914" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15,13.667a6.757,6.757,0,0,1,8.25,6.583" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
    );
}

export default User;