import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { useMemo } from "react";
import { IAuthContext, useAuth } from "../components/AuthProvider";
import { useConfig } from "../components/ConfigProvider";
import { customHistory } from "../components/CustomRouter";
import { IFormResult } from "../components/Form/ManagedForm";

interface IEndpointResult<T> {
    StatusCode: number,
    Data: T,
    Error: IEnpointResultError,
}
interface IEnpointResultError {
    Message: string
}

type IAuth = {
    auth: IAuthContext | null
}

type IBaseCom = {
    url: string,
    headers?: AxiosRequestHeaders
}

type IGetParams<T> = {
    successCallback?: (data: T) => void,
    errorCallback?: (response: AxiosResponse) => void
} & IBaseCom

type IPostParams = {
    data: any
} & IBaseCom

export const useCommunications = () => {
    const auth = useAuth();
    const config = useConfig();
    return useMemo(() => {
        return ({
            get: <T,>(
                url: string,
                successCallback: (data: T) => void,
                errorCallback?: (response: AxiosResponse) => void,
                headers?: AxiosRequestHeaders
            ) => get<T>({ url: config.serverUrl + url, successCallback, errorCallback, headers, auth }),
            getBase: <T,>(url: string, successCallback: (data: T) => void, errorCallback?: (response: AxiosResponse) => void) => getBase({ successCallback, url: config.serverUrl + url, errorCallback, auth }),
            post: <T,>(url: string, data: any, headers?: AxiosRequestHeaders) => post<T>({ auth, data, url: config.serverUrl + url, headers }),
            postForm: <T,>(url: string, data: any, headers?: AxiosRequestHeaders) => postForm<T>({ auth, data, url: config.serverUrl + url, headers }),
            postBase: <T,>(url: string, data: any, headers?: AxiosRequestHeaders) => postBase<T>({ auth, data, url: config.serverUrl + url, headers })
        })
    }, [auth, config.serverUrl])
}

function get<T>({ auth, url, headers, successCallback, errorCallback }: IGetParams<T> & IAuth): void {
    const opt = GetOptions(auth, headers);
    axios.get<IEndpointResult<T>>(url, opt)
        .then(response => {

            if (successCallback)
                successCallback(response.data.Data)
        })
        .catch((reason: AxiosError) => {
            if (reason.response?.status === 403 && reason.response?.data === "noValidLicense") {
                if (errorCallback)
                    errorCallback(reason.response!)
                return;
            }

            if (reason.response?.status === 403)
                auth?.logout();

            if (reason.response?.status === 404)
                customHistory.push("/notfound");

            if (errorCallback)
                errorCallback(reason.response!)
        });
}

function getBase<T>({ auth, url, successCallback, errorCallback }: IGetParams<T> & IAuth): void {
    axios.get<T>(url, GetOptions(auth, { 'Accept': 'text/plain' }))
        .then(response => {
            if (successCallback)
                successCallback(response.data)
        })
        .catch((reason: AxiosError) => {
            if (reason.response?.status === 403 && reason.response?.data === "noValidLicense") {
                if (errorCallback)
                    errorCallback(reason.response!)
                return;
            }

            if (reason.response?.status === 403)
                auth?.logout();

            if (reason.response?.status === 404)
                customHistory.push("/notfound");

            if (errorCallback)
                errorCallback(reason.response!)
        });
}

function post<T>({ auth, url, headers, data }: IPostParams & IAuth): Promise<T | undefined> {
    return axios.post<IEndpointResult<T>>(url, data, GetOptions(auth, headers))
        .then(response => {

            return handleStatusCode<T>(response.data, auth);
        })
        .catch((reason: AxiosError) => {
            if (reason.response?.status === 403 && reason.response?.data === "noValidLicense")
                return;

            if (reason.response?.status === 403)
                auth?.logout();

            return handleStatusCode<T>(reason.response?.data, auth);
        });
}

function postForm<T>({ auth, url, data, headers }: IPostParams & IAuth) {
    return post<IFormResult<T>>({ auth, url, data, headers });
}

function postBase<T>({ auth, url, data, headers }: IPostParams & IAuth): Promise<T | undefined> {
    return axios.post<T>(url, data, GetOptions(auth, headers))
        .then(response => {
            return response.data as T;
        })
        .catch((reason: AxiosError) => {
            if (reason.response?.status === 403 && reason.response?.data === "noValidLicense")
                return;

            if (reason.response?.status === 403)
                auth?.logout();

            if (reason.response?.status === 404)
                customHistory.push("/notfound");

            return undefined;
        });
}

function handleStatusCode<T>(result: IEndpointResult<T>, auth: IAuthContext | null) {
    if (!result)
        return;

    switch (result.StatusCode) {
        case 200:
            return result.Data as T;
        case 302:
            customHistory.push(result.Data as unknown as string);
            break;
        case 401:
        case 403:
            auth?.logout();
            break;
        case 409:
            return result.Data as T;
    }
}

function GetOptions(auth: IAuthContext | null, customHeaders?: AxiosRequestHeaders): AxiosRequestConfig<any> {
    const token = auth?.sessionKey;

    if (!customHeaders) {
        customHeaders = { 'Content-Type': 'application/json' }
    }

    if (token) {
        return {
            headers: { 'AUTHTOKEN': token, 'Accept': 'application/json', ...customHeaders },
            withCredentials: true
        }
    }

    return {
        headers: { ...customHeaders, 'Accept': 'application/json' },
        withCredentials: true
    }
}