import React, { ButtonHTMLAttributes } from "react";

import "../styles/Button.css";
import { usePerm } from "./AuthProvider";

type Color = "primary" | "secondary" | "accent" | "success" | "warning" | "error" | "transparent"

interface IButton extends React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    color?: Color;
    width?: string;
    permission?: string;
    strictPermission?: boolean
}

const Button: React.FunctionComponent<IButton> = (props) => {
    const auth = usePerm();
    let disabled = props.disabled;
    const { strictPermission, permission, ...passDownProps } = props;
    if (permission)
        if (!auth || !auth.hasAccess(permission, strictPermission))
            disabled = true;

    return (
        <button type="button" className={`btn btn-${props.color} ${disabled ? 'disabled' : ''}`} {...passDownProps} disabled={disabled} >{props.children}</button>
    );
}

Button.defaultProps = {
    color: "primary"
}

export type { IButton }

export default Button;