import EmailInput from "../EmailInput";
import { IFormInput } from "./FormInput";

interface IFormEmailInput extends IFormInput {
}

const FormEmailInput: React.FunctionComponent<IFormEmailInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.value);
    };

    return (
        <EmailInput key={props.Id} id={props.Id.toString()} value={props.Value ?? ""} label={props.Label} onChange={onChange} errorText={props.Validation?.MessageKey} />
    );
}

export type {  IFormEmailInput }

export default FormEmailInput;