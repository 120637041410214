const DesignManager: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" height={20}>
            <path d="M22.63 14.87 15 22.5l-3.75.75.75-3.75 7.63-7.63a2.114 2.114 0 0 1 2.992 0l.008.008a2.114 2.114 0 0 1 0 2.992zM8.25 20.25h-6a1.5 1.5 0 0 1-1.5-1.5V2.25a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5V9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M9.75 17.25h-4.5a.75.75 0 0 1-.75-.75v-3.75a.75.75 0 0 1 .75-.75H15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <rect x="4.5" y="3.75" width="10.5" height="5.25" rx=".75" ry=".75" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></rect>
        </svg>
    );
}

export default DesignManager;