interface IApplicationSubmitted {
    
}
 
const ApplicationSubmitted: React.FunctionComponent<IApplicationSubmitted> = () => {
    return (
        <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexFlow: "column"}}>
            <h2>Bewerbung wurde erfolgreich eingereicht.</h2>
            <h3>Sie erhalten in Kürze eine Bestätigungsmail von uns.</h3>
        </div>
    );
}
 
export default ApplicationSubmitted;