import { useCallback, useEffect, useReducer, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "../components/Button";
import DataGrid from "../components/DataGrid";
import { useCommunications } from "../services/Communication";
import "../styles/Vacancy.css";
import { reducer, ReducerOptions } from "./VacanciesReducer";
import Checkbox from "../components/Checkbox";
import Popup from "../components/Popup";
import Form from "../components/Form";
import TextInput from "../components/TextInput";
import SubmitButton from "../components/SubmitButton";
import { IModelState, IValidationState } from "../components/Form/ManagedForm";
import MultiSelectInput from "../components/MultiSelectInput";
import Icon from "../components/Icon";
import CopyInput from "../components/CopyInput";
import { useNavigate, useParams } from "react-router";
import FieldSelection from "../components/FieldSelection";


interface Vacancy {
    Name: string,
    VacancyId: number,
    Active: boolean
}

const DropType = {
    DefaultFields: "default",
    DocumentFields: "documents",
    PrivacyNoticeFields: "privacyNotice"
} as const;

interface IEditProps {
    SelectedFieldsSourceUrl: string,
    SelectedFieldsSaveUrl: string,
    onClose?: () => void
}

export interface ISelectedFields {
    Fields: Array<IVacancyField>
    DocumentFields: Array<IVacancyField>
    PrivacyNoticeFields: Array<IVacancyField>
}

export interface IVacancyFieldsInfo extends ISelectedFields {
    Vacancy: Vacancy,
    CustomVacancyName: string
}

export const isVacancyFieldsInfo = (data: IVacancyFieldsInfo | ISelectedFields): data is IVacancyFieldsInfo => {
    if ((data as IVacancyFieldsInfo).Vacancy)
        return true
    return false;
}

export interface IVacancyField {
    VacancyNr: number,
    FieldId: number,
    Required: boolean,
    OrderPosition: number
}


export interface IField {
    Id: number,
    Name: string,
    Type: string,
    Required: boolean,
    AsDefault: boolean,
    Attributes: string
}

export default function Vacancies() {
    const [onlyActive, setOnlyActive] = useState(true);
    const buildLink = (vac: Vacancy) => window.location.origin + "/applicationform/" + vac.VacancyId;
    const navigate = useNavigate();
    const comm = useCommunications();

    const deleteVacancy = (vacancy: Vacancy) => {
        if (window.confirm("Wollen sie die Stelle wirklich löschen? Noch nicht abgeholte Bewerbungen auf diese Stelle können dann nicht mehr abgeholt werden.")) {
            comm.post<boolean>("/vacancies/Delete", vacancy);
            return true;
        }
        return false;
    }

    let hasValidId = true;
    let { id } = useParams();
    if (!id || isNaN(Number(id)))
        hasValidId = false;

    return (
        <>
            {!hasValidId ?
                <DataGrid<Vacancy>
                    RowDefinition={{
                        ColumnDefinitions: [
                            { Field: "VacancyId", Title: "Nummer" },
                            { Field: "Name", Title: "Bezeichnung" },
                            { Field: "Active", CustomCell: (di) => <Checkbox checked={di.Active} disabled />, Title: "Activ" },
                            { CustomCell: (di) => <CopyInput value={buildLink(di)} />, Title: "IFramelink" }
                        ],
                        ButtonDefinitions: {
                            onDelete: deleteVacancy,
                            onDeleteText: "Löschen"
                        }
                    }}
                    Sortable
                    onRowClick={(item => navigate("/vacancies/" + item.VacancyId))}
                    DataSource={"/vacancies/Vacancies" + (onlyActive ? "?onlyActive" : "")}
                    Discriminator=""
                    CustomToolBarItems={[<Checkbox checked={onlyActive} onChange={e => setOnlyActive(e.target.checked)} label="nur Aktive zeigen" />]}
                />
                :
                <EditSelectedFields
                    SelectedFieldsSourceUrl={`/Vacancies/Vacancy?vacancyId=${id}`}
                    SelectedFieldsSaveUrl={`/Vacancies/SaveVacancy`}
                    onClose={() => navigate("/vacancies")}
                />
            }
        </>
    );
}

export const EditSelectedFields = (props: IEditProps) => {
    const [selectedFields, selectedFieldsDispatch] = useReducer(reducer, undefined as any);

    const [possibleFields, setPossibleFields] = useState<Array<IField>>([]);
    const [possibleDocumentFields, setPossibleDocumentFields] = useState<Array<IField>>([]);
    const [possiblePrivacyNoticeFields, setPossiblePrivacyNoticeFields] = useState<Array<IField>>([]);
    const [documentPopupOpen, setDocumentPopupOpen] = useState<boolean>(false);
    const [privacyNoticePopupOpen, setPrivacyNoticePopupOpen] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<IField>();
    const [selectedPrivacyNotice, setSelectedPrivacyNotice] = useState<IField>();
    const { get, postForm, post } = useCommunications();

    useEffect(() => {
        get<ISelectedFields>(props.SelectedFieldsSourceUrl, data => {
            selectedFieldsDispatch({ type: ReducerOptions.SET_STATE, newState: data });
        });
        get<Array<IField>>("/FormFields/PossibleFields", data => {
            setPossibleFields(data)
        });
        get<Array<IField>>("/FormFields/PossibleDocumentFields", data => {
            setPossibleDocumentFields(data);
        });
        get<Array<IField>>("/FormFields/PossiblePrivacyNoticeFields", data => {
            setPossiblePrivacyNoticeFields(data);
        });
    }, [props.SelectedFieldsSourceUrl, get])

    const handleDrop = useCallback((dragIndex: number, hoverIndex: number, newFieldId: number | string) => {
        selectedFieldsDispatch({ type: ReducerOptions.ADD_AT_POSITION, newField: possibleFields.find(x => x.Id === newFieldId)!, newPosition: hoverIndex, oldPosition: dragIndex });
    }, [possibleFields])

    const handleDocumentDrop = useCallback((dragIndex: number, hoverIndex: number, newFieldId: number | string) => {
        const movingDoc = possibleDocumentFields.find(x => x.Id === newFieldId);
        if (!movingDoc) {
            console.error("not found: " + newFieldId);
            return;
        }
        selectedFieldsDispatch({ type: ReducerOptions.ADD_DOCUMENT_AT_POSITION, newField: movingDoc, newPosition: hoverIndex, oldPosition: dragIndex });
    }, [possibleDocumentFields]);

    const handlePrivacyNoticeDrop = useCallback((dragIndex: number, hoverIndex: number, newFieldId: number | string) => {
        selectedFieldsDispatch({ type: ReducerOptions.ADD_PRIVACY_NOTICE_AT_POSITION, newField: possiblePrivacyNoticeFields.find(x => x.Id === newFieldId)!, newPosition: hoverIndex, oldPosition: dragIndex });
    }, [possiblePrivacyNoticeFields])

    if (!selectedFields)
        return <></>;

    const removeField = (id: number) => {
        selectedFieldsDispatch({ type: ReducerOptions.REMOVE_FIELD, removedFieldId: id })
    }

    const removeDocumentField = (id: number) => {
        selectedFieldsDispatch({ type: ReducerOptions.REMOVE_DOCUMENT_FIELD, removedFieldId: id })
    }

    const removePrivacyNoticeField = (id: number) => {
        selectedFieldsDispatch({ type: ReducerOptions.REMOVE_PRIVACY_NOTICE_FIELD, removedFieldId: id })
    }

    const toggleRequired = (fieldId: number) => {
        let newFields = [...selectedFields.Fields];
        const curr = newFields.find(x => x.FieldId === fieldId);

        if (curr)
            curr.Required = !curr.Required;

        selectedFieldsDispatch({ type: ReducerOptions.REPLACE_FIELDS, fields: newFields });
    }

    const toggleDocumentRequired = (fieldId: number) => {
        let newFields = [...selectedFields.DocumentFields];
        const curr = newFields.find(x => x.FieldId === fieldId);

        if (curr)
            curr.Required = !curr.Required;

        selectedFieldsDispatch({ type: ReducerOptions.REPLACE_DOCUMENT_FIELDS, fields: newFields });
    }

    const togglePrivacyNoticeRequired = (fieldId: number) => {
        let newFields = [...selectedFields.PrivacyNoticeFields];
        const curr = newFields.find(x => x.FieldId === fieldId);

        if (curr)
            curr.Required = !curr.Required;

        selectedFieldsDispatch({ type: ReducerOptions.REPLACE_PRIVACY_NOTICE_FIELDS, fields: newFields });
    }

    const saveVacancy = (silent: boolean = false) => {
        postForm<boolean>(props.SelectedFieldsSaveUrl,
            {
                ...selectedFields,
                Fields: selectedFields.Fields.map((x, i) => ({ ...x, OrderPosition: i })),
                DocumentFields: selectedFields.DocumentFields.map((x, i) => ({ ...x, OrderPosition: i })),
                PrivacyNoticeFields: selectedFields.PrivacyNoticeFields.map((x, i) => ({ ...x, OrderPosition: i }))
            }
        ).then(result => {

            if (result?.Data) {
                if (silent)
                    return;

                alert("Änderungen wurden erfolgreich gespeichert.")

                if (props.onClose)
                    props.onClose();
            }
            else if (result?.ModelState) {
                const errtxt = result.ModelState.Validations.reduce((prev, curr) => prev + ", " + curr.MessageKey, "")
                alert("Etwas ist schief gelaufen: " + errtxt);
            }
        });
    }

    const handleClose = () => {
        if (!props.onClose)
            return;

        if (!window.confirm("Möchten Sie wirklich abbrechen?\nDadurch werden die vorgenommenen Änderungen nicht übernommen."))
            return;

        props.onClose()
    }

    const handleDocumentAddButton = () => {
        setDocumentPopupOpen(true);
    }

    const handlePrivacyNoticeAddButton = () => {
        setPrivacyNoticePopupOpen(true);
    }

    const handlePopupStateChanged = (open: boolean) => {
        setDocumentPopupOpen(open);
    }

    const handlePrivacyNoticePopupStateChanged = (open: boolean) => {
        setPrivacyNoticePopupOpen(open);
    }

    const handleDocumentFieldDelete = async (field: IField) => {
        if (!window.confirm("Möchten Sie das Feld wirklich löschen?"))
            return;

        let success = await post<boolean>("/FormFields/RemoveDocumentField", field.Id);

        if (success) {
            setPossibleDocumentFields(possibleDocumentFields.filter(x => x.Id !== field.Id));
        }
        else {
            alert("Etwas ist schief gelaufen.")
        }
    }

    const handleDocumentFieldEdit = async (field: IField) => {
        setSelectedDocument(field);
        setDocumentPopupOpen(true);
    }

    const handlePrivacyNoticeFieldDelete = async (field: IField) => {
        if (!window.confirm("Möchten Sie das Feld wirklich löschen?"))
            return;

        let success = await post<boolean>("/FormFields/RemovePrivacyNoticeField", field.Id);

        if (success) {
            setPossiblePrivacyNoticeFields(possiblePrivacyNoticeFields.filter(x => x.Id !== field.Id));
        }
        else {
            alert("Etwas ist schief gelaufen.")
        }
    }

    const handlePrivacyNoticeFieldEdit = async (field: IField) => {
        setSelectedPrivacyNotice(field);
        setPrivacyNoticePopupOpen(true);
    }

    interface IDocumentPopupProps {
        id?: number;
        name?: string;
        extensions?: string[];
    }

    const DocumentPopup = (props: IDocumentPopupProps) => {
        const [name, setName] = useState<string>(props.name ?? "");
        const extensions = [{ title: "PDF", value: ".pdf" }, { title: "JPG", value: ".jpg, .jpeg" }, { title: "PNG", value: ".png" }]
        const documentExtensions = getDocumentExtensions();
        const [selectedExtensions, setSelectedExtensions] = useState<DocumentAcceptedTypes[]>(documentExtensions ?? []);
        const [validationState, setValidationState] = useState<IValidationState>({});

        function getDocumentExtensions() {
            return props.extensions?.map(e => {
                return extensions.find(x => x.value.includes(e));
            })
            .filter((x, i, arr) => x !== undefined && arr.indexOf(x) === i) as DocumentAcceptedTypes[];
        }

        function fillValidation(validationResponse?: IModelState) {
            if (!validationResponse)
                return;

            let validations = {} as IValidationState;
            validationResponse.Validations.forEach(error => {
                validations[error.Key] = error;
            });
            setValidationState(validations);
        }

        const handleDocumentFieldSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const endpoint = props.id ? "EditDocumentField" : "AddDocumentField"

            let data = {}

            if (props.id) {
                data = { Id: props.id, Name: name, Accept: selectedExtensions?.map(x => x.value) }
            }
            else {
                data = { Name: name, Accept: selectedExtensions?.map(x => x.value) }
            }

            let formResult = await postForm<IField>(`/FormFields/${endpoint}`, data, { "Content-Type": "plain/text" });

            if (!formResult) {
                setDocumentPopupOpen(false);
                setSelectedDocument(undefined);
                return alert("Etwas ist schief gelaufen.")
            }

            if (!formResult.Data)
                return fillValidation(formResult?.ModelState)
            else {
                if (props.id) {
                    let oldIndex = possibleDocumentFields.findIndex(x => x.Id === props.id);

                    if (oldIndex < 0)
                        return;

                    possibleDocumentFields[oldIndex] = formResult.Data;
                }
                else {
                    possibleDocumentFields.push(formResult.Data as IField);
                }
            }

            setDocumentPopupOpen(false);
            setSelectedDocument(undefined);
        }
        return (
            <Popup open={documentPopupOpen} openChanged={handlePopupStateChanged} width="25%" height="fit-content">
                <Form onSubmit={handleDocumentFieldSubmit}>
                    <h2 style={{ marginTop: 0 }}>Dokument hinzufügen</h2>
                    <TextInput label="Name" value={name ?? ""} onChange={(e) => setName(e.target.value)} errorText={validationState["Name"]?.MessageKey} />
                    <MultiSelectInput label="Erlaubte Dateien"
                        data={extensions}
                        value={selectedExtensions}
                        textField="title"
                        dataItemKey="value"
                        onChange={(e) => setSelectedExtensions(e)}
                        errorText={validationState["Attributes"]?.MessageKey} />
                    <SubmitButton color="accent">Speichern</SubmitButton>
                </Form>
            </Popup>
        );
    }

    interface IPrivacyNoticeProps {
        id?: number;
        name?: string;
        link?: string;
    }

    const PrivacyNoticePopup = (props: IPrivacyNoticeProps) => {
        const [name, setName] = useState<string>(props.name ?? "");
        const [link, setLink] = useState<string>(props.link ?? "");
        const [validationState, setValidationState] = useState<IValidationState>({});

        function fillValidation(validationResponse?: IModelState) {
            if (!validationResponse)
                return;

            let validations = {} as IValidationState;
            validationResponse.Validations.forEach(error => {
                validations[error.Key] = error;
            });
            setValidationState(validations);
        }

        const handlePrivacyNoticeFieldSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const endpoint = props.id ? "EditPrivacyNoticeField" : "AddPrivacyNoticeField"

            let data = {}

            if (props.id) {
                data = { Id: props.id, Name: name, Link: link };
            }
            else {
                data = { Name: name, Link: link };
            }

            let formResult = await postForm<IField>(`/FormFields/${endpoint}`, data, { "Content-Type": "plain/text" });

            if (!formResult) {
                setPrivacyNoticePopupOpen(false);
                setSelectedPrivacyNotice(undefined);
                return alert("Etwas ist schief gelaufen.")
            }

            if (!formResult.Data) {
                return fillValidation(formResult?.ModelState)
            }
            else {
                if (props.id) {
                    let oldIndex = possiblePrivacyNoticeFields.findIndex(x => x.Id === props.id);

                    if (oldIndex < 0)
                        return;

                    possiblePrivacyNoticeFields[oldIndex] = formResult.Data;
                }
                else {
                    possiblePrivacyNoticeFields.push(formResult.Data as IField);
                }
            }

            setPrivacyNoticePopupOpen(false);
            setSelectedPrivacyNotice(undefined);
        }

        return (
            <Popup open={privacyNoticePopupOpen} openChanged={handlePrivacyNoticePopupStateChanged} width="25%" height="fit-content">
                <Form onSubmit={handlePrivacyNoticeFieldSubmit}>
                    <h2 style={{ marginTop: 0 }}>Datenschutz hinzufügen</h2>
                    <TextInput label="Name" value={name ?? ""} onChange={(e) => setName(e.target.value)} errorText={validationState["Name"]?.MessageKey} />
                    <TextInput label="Link" value={link ?? ""} onChange={(e) => setLink(e.target.value)} errorText={validationState["Link"]?.MessageKey} />
                    <SubmitButton color="accent">Speichern</SubmitButton>
                </Form>
            </Popup>
        );
    }

    const handleVacancyNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        selectedFieldsDispatch({ type: ReducerOptions.CHANGE_NAME, name: e.target.value });
    }
    const handleVacancyActiveStateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        selectedFieldsDispatch({ type: ReducerOptions.CHANGE_ACTIVE_STATE, active: e.target.checked });
    }

    return (<>
        <DndProvider backend={HTML5Backend}>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {isVacancyFieldsInfo(selectedFields) && <>
                        <h5 style={{ width: "100%" }} >
                            <TextInput value={selectedFields.CustomVacancyName} onChange={handleVacancyNameChanged} width="100%" />
                        </h5>

                    </>
                    }

                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginLeft: 10 }}>
                        {isVacancyFieldsInfo(selectedFields) ?
                            <>
                                <span>({selectedFields.Vacancy.Name})</span>
                                <Checkbox checked={selectedFields.Vacancy.Active} onChange={handleVacancyActiveStateChanged} label="Activ" />
                            </>
                            :
                            <></>
                        }

                        <Button color="accent" permission="vacancies:save" onClick={() => saveVacancy()}>Speichern</Button>
                        {props.onClose &&
                            <Button color="error" onClick={() => handleClose()}>Abbrechen</Button>
                        }
                    </div>
                </div>
                <FieldSelection
                    titel="Felder"
                    fieldDropType={DropType.DefaultFields}
                    possibleFields={possibleFields}
                    selectedFields={selectedFields.Fields}
                    toggleRequired={toggleRequired}
                    handleDrop={handleDrop}
                    removeField={removeField}
                />
                <DocumentPopup id={selectedDocument?.Id} name={selectedDocument?.Name} extensions={selectedDocument?.Attributes.split(",").map(x => x.trim())} />
                <FieldSelection
                    titel="Dokumente"
                    fieldDropType={DropType.DocumentFields}
                    possibleFields={possibleDocumentFields}
                    selectedFields={selectedFields.DocumentFields}
                    toggleRequired={toggleDocumentRequired}
                    handleDrop={handleDocumentDrop}
                    removeField={removeDocumentField}
                    onDelete={handleDocumentFieldDelete}
                    onEdit={handleDocumentFieldEdit}
                    onAdd={handleDocumentAddButton}
                />

                <PrivacyNoticePopup id={selectedPrivacyNotice?.Id} name={selectedPrivacyNotice?.Name} link={selectedPrivacyNotice?.Attributes} />
                <FieldSelection
                    titel="Datenschutz"
                    fieldDropType={DropType.PrivacyNoticeFields}
                    possibleFields={possiblePrivacyNoticeFields}
                    selectedFields={selectedFields.PrivacyNoticeFields}
                    toggleRequired={togglePrivacyNoticeRequired}
                    handleDrop={handlePrivacyNoticeDrop}
                    removeField={removePrivacyNoticeField}
                    onDelete={handlePrivacyNoticeFieldDelete}
                    onEdit={handlePrivacyNoticeFieldEdit}
                    onAdd={handlePrivacyNoticeAddButton}
                />
            </div>
        </DndProvider>
    </>)
}

interface DocumentAcceptedTypes {
    title: string,
    value: string
}

interface IFieldIconProps {
    fieldType: number;
}

export const FieldIcon = (props: IFieldIconProps) => {
    switch (props.fieldType) {
        case 0:
        case 3:
            return <Icon icon="letters" />;
        case 1:
            return <Icon icon="date" />;
        case 2:
            return <Icon icon="pdf" />
        case 4:
            return <Icon icon="dropdown" />
        case 5:
        case 6:
        case 8:
            return <Icon icon="numerical" />
        case 7:
            return <Icon icon="dropdown" />;
    }

    return <></>
}