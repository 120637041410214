import React, { useEffect, useState } from 'react'

import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { DataResult, process, State } from "@progress/kendo-data-query";

import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

import { useCommunications } from "../services/Communication";

import * as Localization from '../services/Localization';

import styled from "styled-components";
import { IComponent } from './Component';
import DataGridButtonCell from './DataGridButtonCell';
import Button from './Button';
import DataGridPopupForm from './DataGridPopupForm';
import { IFormInput } from './Form/FormInput';
import { IFormResult, IModelState, IValidationState } from './Form/ManagedForm';
import { SortSettings } from '@progress/kendo-react-data-tools';

interface IDataGrid<T> extends IComponent {
    RowDefinition: IRowDefinition<T>,
    DataSource: string,
    Sortable?: SortSettings | undefined
    Filterable?: boolean,
    onRowClick?: (dataItem: T) => void,
    CustomToolBarItems?: JSX.Element[] | undefined
}

interface IRowDefinition<T> {
    DataItemKey?: Extract<keyof T, string>,
    ColumnDefinitions: IColumnDefinition<T>[]
    ButtonDefinitions?: IGridCommandDefinition<T>
}

interface IGridCommandDefinition<T> {
    editForm?: IFormInput<T>[],
    onCustomText?: string,
    onClick?: (dataItem: T) => boolean,
    onDeleteText?: string,
    onDelete?: (dataItem: T) => boolean,
    onCreateText?: string,
    onCreate?: (dataItem: T) => Promise<IFormResult<T>>,
    onEditText?: string,
    onEdit?: (dataItem: T) => Promise<IFormResult<T>>,
}

type IColumnDefinition<T> = IAutoColumnDefinition<T> | ICustomColumnDefinition<T>;

interface IBaseColumnDefinition<T> {
    Title: string,
    Field?: Extract<keyof T, string>
}

interface IAutoColumnDefinition<T> extends IBaseColumnDefinition<T> {
    Field: Extract<keyof T, string>
}

interface ICustomColumnDefinition<T> extends IBaseColumnDefinition<T> {
    CustomCell: (data: T) => JSX.Element
}

const StyledGrid = styled(Grid)`
    color: var(--color-foreground);
    background-color: var(--color-primary);
    border-color: var(--color-secondary);
    & .k-grouping-header {
        color: var(--color-foreground);
        background-color: var(--color-primary);
        border-color: var(--color-secondary);
    }
    & .k-grid-header {
        background-color: var(--color-primary);
        border-color: var(--color-secondary);
    }
    & span.k-icon {
        color: var(--color-accent);
    }
    & th {
        color: var(--color-foreground);
        background-color: var(--color-primary);
        border-color: var(--color-secondary);
    }
    & th.k-sorted {
        background-color: var(--color-secondary) !important;
    }
    & td {
        border-color: var(--color-secondary);
    }
    & tr.k-grouping-row {
        color: var(--color-foreground);
        background-color: var(--color-primary) !important;
        border-color: var(--color-secondary);
    }
    & td.k-group-cell {
        color: var(--color-foreground);
        background-color: var(--color-primary) !important;
        border-color: var(--color-secondary);
    }
`;

enum FormState {
    Create,
    Edit
}

function DataGrid<T>(props: IDataGrid<T>) {
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const [dataState, setDataState] = React.useState<State>({});
    const [openForm, setOpenForm] = React.useState<boolean>(false);
    const [formState, setFormState] = React.useState<FormState>(FormState.Create);
    const [formEditItem, setEditItem] = React.useState<T>({} as T);

    const [validationState, setValidationState] = useState<IValidationState>({});
    const [reload, setReload] = useState(false);
    const { get } = useCommunications();

    const afterDeleteItem = (dataItem: T) => {
        let newData = [...gridData.data]
        const idx = newData.findIndex(x => x === dataItem);
        newData.splice(idx, 1);
        setGridData({ ...gridData, data: newData, total: newData.length })
    }

    const afterEditItem = (dataItem: T) => {
        let newData = [...gridData.data];
        if (props.RowDefinition.DataItemKey) {
            const idx = newData.findIndex(x => x[props.RowDefinition.DataItemKey] === dataItem[props.RowDefinition.DataItemKey!]);
            newData[idx] = dataItem;
            setGridData({ ...gridData, data: newData, total: newData.length })
        }
        else {
            setReload(!reload);
        }

    }

    const afterCreateItem = (dataItem: T) => {
        let newData = [...gridData.data]
        newData.push(dataItem);
        setGridData({ ...gridData, data: newData, total: newData.length })
    }

    const onDeleteItemClick = (dataItem: T) => {
        if (!props.RowDefinition.ButtonDefinitions?.onDelete)
            return;
        if (props.RowDefinition.ButtonDefinitions?.onDelete(dataItem))
            afterDeleteItem(dataItem);
    }

    const onEditItemClick = (dataItem: T) => {
        if (!props.RowDefinition.ButtonDefinitions?.onEdit)
            return;
        props.RowDefinition.ButtonDefinitions?.onEdit(dataItem).then(response => {
            if (response.ModelState?.IsValid) {
                setValidationState({});
                afterEditItem(response.Data as T);
                setOpenForm(false);
            } else {
                fillValidation(response.ModelState!)
            }
        })
    }

    const onCreateItemClick = (dataItem: T) => {
        if (!props.RowDefinition.ButtonDefinitions?.onCreate)
            return;
        props.RowDefinition.ButtonDefinitions?.onCreate(dataItem).then(response => {
            if (response.ModelState?.IsValid) {
                setValidationState({});
                afterCreateItem(response.Data as T);
                setOpenForm(false);
            } else {
                fillValidation(response.ModelState!)
            }
        })
    }

    function fillValidation(validationResponse: IModelState) {
        let validations = {} as IValidationState;
        validationResponse.Validations.forEach(error => {
            validations[error.Key] = error;
        });
        setValidationState(validations);
    }

    const onCustomItemClick = (dataItem: T) => {
        if (!props.RowDefinition.ButtonDefinitions?.onClick)
            return;
        props.RowDefinition.ButtonDefinitions?.onClick(dataItem)
    }

    const CommandCell = (cellProps: GridCellProps) => (
        <DataGridButtonCell
            {...cellProps}
            onEditText={props.RowDefinition.ButtonDefinitions?.onEditText}
            hasEdit={props.RowDefinition.ButtonDefinitions?.onEdit !== undefined}
            onEdit={enterEdit}
            onCustomText={props.RowDefinition.ButtonDefinitions?.onCustomText}
            hasCustom={props.RowDefinition.ButtonDefinitions?.onClick !== undefined}
            onCustom={onCustomItemClick}
            onDeleteText={props.RowDefinition.ButtonDefinitions?.onDeleteText}
            hasDelete={props.RowDefinition.ButtonDefinitions?.onDelete !== undefined}
            onDelete={onDeleteItemClick} />
    );

    const enterEdit = (item: T) => {
        setFormState(FormState.Edit);
        setEditItem(item);
        setValidationState({});
        setOpenForm(true);
    };

    const enterCreate = () => {
        setFormState(FormState.Create);
        setEditItem({} as T);
        setValidationState({});
        setOpenForm(true);
    };

    const handleSubmit = (dataItem: T) => {
        if (formState === FormState.Create)
            onCreateItemClick(dataItem);
        else if (formState === FormState.Edit)
            onEditItemClick(dataItem);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
        setValidationState({});
    };

    useEffect(() => {
        get<T[]>(props.DataSource, endpointData => {
            setGridData({ data: endpointData, total: endpointData.length });
        });
    }, [props.DataSource, setGridData, reload, get]);

    const isCustomColumn = (col: IColumnDefinition<T>): col is ICustomColumnDefinition<T> => {
        return (col as ICustomColumnDefinition<T>).CustomCell !== undefined;
    }

    const buildColumns = () => {
        const cols: JSX.Element[] = [];
        props.RowDefinition.ColumnDefinitions.forEach((col, index) => {
            if (!isCustomColumn(col))
                cols.push(
                    <GridColumn key={index} field={col.Field} title={col.Title} />
                );
            else
                cols.push(
                    <GridColumn key={index}
                        cell={props => <td>{col.CustomCell(props.dataItem)}</td>}
                        title={col.Title}
                        field={col.Field}
                    />
                );

        })
        if (props.RowDefinition.ButtonDefinitions) {
            cols.push(<GridColumn key={"buttons"} cell={CommandCell} />);
        }
        return cols;
    };
    const columns = buildColumns();

    const toolbarItems: JSX.Element[] = []
    if (props.RowDefinition.ButtonDefinitions?.onCreate)
        toolbarItems.push(<Button color="accent" onClick={enterCreate}>{props.RowDefinition.ButtonDefinitions.onCreateText ?? "Hinzufügen"}</Button>);
    props.CustomToolBarItems?.forEach(x => toolbarItems.push(x));
    return (
        <LocalizationProvider language={Localization.GetLanguage()}>
            <IntlProvider locale={Localization.GetLocale()}>
                {toolbarItems.length > 0 &&
                    <GridToolbar>
                        {toolbarItems.map((x, i) => <React.Fragment key={i}>{x}</React.Fragment>)}
                    </GridToolbar>
                }
                <StyledGrid
                    dataItemKey={props.RowDefinition.DataItemKey}
                    data={process<T>(gridData.data, dataState)}
                    sortable={props.Sortable}
                    filterable={props.Filterable ?? false}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        setDataState(e.dataState);
                    }}
                    onRowClick={
                        (e) => {
                            if (props.onRowClick)
                                props.onRowClick(e.dataItem);
                        }
                    }
                >
                    {columns}
                </StyledGrid>
                {props.RowDefinition.ButtonDefinitions?.editForm && openForm && (
                    <DataGridPopupForm<T>
                        dataItem={formEditItem}
                        Id="DataGridPopupForm"
                        onSubmit={handleSubmit}
                        onAbort={handleCancelEdit}
                        onInputChange={(data) => { setEditItem(data) }}
                        validationState={validationState}
                        Inputs={props.RowDefinition.ButtonDefinitions?.editForm} />

                )}
            </IntlProvider>
        </LocalizationProvider>
    )
}

export type { IDataGrid }

export default DataGrid