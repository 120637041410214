import { InputWithButton } from "./InputWithButton";

interface ICopyInput {
    value: string
}
const copyLinkToClipBoard = (link: string) => {
    navigator.clipboard.writeText(link);
    alert("Wert '" + link + "' in die Zwischenablage kopiert")
}

export default function CopyInput({value}: ICopyInput) {
    return <InputWithButton inputprops={{ value }} buttonProps={{ icon: "copy", onClick: () => copyLinkToClipBoard(value) }} />
}