import { useEffect, useState } from "react";
import { IComponent } from "../components/Component";
import { useConfig } from "../components/ConfigProvider";
import ManagedForm, { IManagedForm } from "../components/Form/ManagedForm";
import {useCommunications } from "../services/Communication";

export interface IView {
    Components: IComponent[];
}

interface IServerPageProps {
    url: string
}

export default function ServerFormView({ url }: IServerPageProps) {
    const [form, setForm] = useState<IManagedForm>();
    const config = useConfig();
    const {get} = useCommunications();
    useEffect(() => {
        get<IView>(url, view => {
            setForm(view.Components[0] as IManagedForm);
        });
    }, [config, url, get]);
    if (!form)
        return <></>
    return <ManagedForm {...form} />
}