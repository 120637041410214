import PhoneInput from "../PhoneInput";
import { IFormInput } from "./FormInput";

interface IFormPhoneInput extends IFormInput {
}

const FormPhoneInput: React.FunctionComponent<IFormPhoneInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.value);
    };

    return (
        <PhoneInput key={props.Id} id={props.Id.toString()} label={props.Label}  onChange={onChange} errorText={props.Validation?.MessageKey} />
    );
}

export type {  IFormPhoneInput }

export default FormPhoneInput;