import NumberInput from "../NumberInput";
import { IFormInput } from "./FormInput";


interface IFormNumberInput extends IFormInput {
    RegxPattern?: string;
}

const FormNumberInput: React.FunctionComponent<IFormNumberInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, +event.target.value);
    };

    return (
        <NumberInput pattern={props.RegxPattern}
            defaultValue={props.Value ?? 0}
            step={props.RegxPattern ? "0.01" : undefined}
            key={props.Id}
            id={props.Id.toString()}
            label={props.Label}
            onChange={onChange}
            errorText={props.Validation?.MessageKey} />
    );
}

export type { IFormNumberInput }

export default FormNumberInput;