import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCommunications } from "../services/Communication";
import { usePerm } from "./AuthProvider";

interface ILicense {
    IsActive: boolean;
}

const LicenseContext = React.createContext<ILicense | undefined>(undefined);

export function LicenseProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    let [license, setLicense] = useState<ILicense>();

    const auth = usePerm();
    const { get } = useCommunications();

    useEffect(() => {
        if (!auth?.sessionKey)
            return;

        get<ILicense>("/license/get", license => {
            setLicense(license);
        });
    }, [auth, get]);

    const value = useMemo<ILicense>(
        () => ({
            IsActive: license?.IsActive ?? true
        }),
        [license]
    );

    return (
        <LicenseContext.Provider value={value}>
            {children}
        </LicenseContext.Provider>
    );
}
export function useLicense() { return useContext(LicenseContext) }

