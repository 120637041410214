import React, { useEffect, useState } from "react";
import "../styles/ColorPicker.css";

interface ColorPickerProps {
    label?: string;
    width?: string;
    value?: string;
    onChange(value: string): void;
}

const ColorPicker: React.FunctionComponent<ColorPickerProps> = (props) => {
    const [color, setColor] = useState<string>(props.value ?? "#ffffff");
    const [updateTimeOut, setUpdateTimeout] = useState<NodeJS.Timeout>();
    function handleColorChange(e: React.ChangeEvent<HTMLInputElement>) {
        clearTimeout(updateTimeOut)
        setUpdateTimeout(setTimeout((value) => {
            setColor(value);
            props.onChange(value);
        }, 200, e.target.value))
    }

    useEffect(() => {
        setColor(props.value ?? "#ffffff");
    }, [props.value])

    return (
        <div className="color-picker-wrapper" style={{ width: props.width }}>
            <span className="color-picker-label">{props.label}</span>
            <div className="color-picker-container" >
                <div className="color-picker-mask">
                    <input type="color" value={color} onChange={handleColorChange} className="color-picker" />
                </div>
                <span>{color}</span>
            </div>
        </div>
    );
}

export default ColorPicker;