
import { useCallback, useEffect, useState } from "react";
import DataForm from "../components/DataForm";
import { FORM_TYPE, IFormInput } from "../components/Form/FormInput";
import { IModelState, IValidationState } from "../components/Form/ManagedForm";
import { useCommunications } from "../services/Communication";
import { MailEditor } from "./MailEditor";


interface ICfgForm<T> {
    Source: string,
    SetConfigUrl: string,
    Inputs: IFormInput<T>[],
    Id: string,
    Title?: string,
    IsValid?: (data: T) => IValidationState,
    preSubmit?: (data: T) => Promise<T>
}

interface ISmtpConfig {
    DefaultSenderAddress: string,
    DefaultSenderName: string,
    Host: string,
    Password: string,
    Port: number,
    User: number,
    UseSsl: boolean
}

interface IBasicConfig {
    ShowVacancyNameOnForm: boolean
}

interface IMailConfig {
    ApplicationAnswerSubject: string,
    ApplicationNoticeSubject: string,
    HrMailReceiver: string
}

export default function Configuration() {
    return <>
        <Config<ISmtpConfig>
            Title="SMTP"
            Id="SmtpConfig"
            SetConfigUrl="/Config/SetSmtpConfig"
            Source="/Config/GetSmtpConfig"
            Inputs={
                [

                    {
                        Discriminator: FORM_TYPE.Email,
                        Id: "DefaultSenderAddress",
                        Label: "Ursprungs E-Mail"
                    },
                    {
                        Discriminator: FORM_TYPE.Text,
                        Id: "DefaultSenderName",
                        Label: "Sendername"
                    },
                    {
                        Discriminator: FORM_TYPE.Text,
                        Id: "Host",
                        Label: "Host"
                    },
                    {
                        Discriminator: FORM_TYPE.Password,
                        Id: "Password",
                        Label: "Passwort"
                    },
                    {
                        Discriminator: FORM_TYPE.Number,
                        Id: "Port",
                        Label: "Port",
                        Default: 25
                    },
                    {
                        Discriminator: FORM_TYPE.Text,
                        Id: "User",
                        Label: "Benutzer"
                    },
                    {
                        Discriminator: FORM_TYPE.CheckBox,
                        Id: "UseSsl",
                        Label: "Ssl bnutzen",
                        Default: false
                    }
                ]
            } />
        <Config<IBasicConfig>
            Title="Sonstiges"
            Id="otherConfig"
            SetConfigUrl="/Config/SetBasicConfig"
            Source="/Config/GetBasicConfig"
            Inputs={[{
                Discriminator: FORM_TYPE.CheckBox,
                Id: "ShowVacancyNameOnForm",
                Label: "Stellennamen anzeigen",
                Default: true
            }]}

        />
        <Config<IMailConfig>
            Title="Mail verkehr"
            Id="otherConfig"
            SetConfigUrl="/Config/SetMailConfig"
            Source="/Config/GetMailConfig"
            Inputs={[{
                Discriminator: FORM_TYPE.Email,
                Id: "HrMailReceiver",
                Label: "HR E-Mail"
            },
            {
                Discriminator: FORM_TYPE.Text,
                Id: "ApplicationAnswerSubject",
                Label: "Betreff Bewerberantwort"
            },
            {
                Discriminator: FORM_TYPE.Text,
                Id: "ApplicationNoticeSubject",
                Label: "Betreff Interne Mail"
            }]}
        />
        <MailEditor />
    </>;
}

export function Config<T>({ Id, Inputs, Source, SetConfigUrl, Title, IsValid, preSubmit }: ICfgForm<T>) {
    const [serverValidationState, setServerValidationState] = useState<IValidationState>({});
    const [clientValidationState, setClientValidationState] = useState<IValidationState>({});
    const [savedData, setSavedData] = useState<T | undefined>(undefined);
    const [data, setData] = useState<T | undefined>(undefined);
    const { get, postForm } = useCommunications();

    const checkAndSetData = useCallback((data: T | undefined) => {
        if (data && IsValid)
            setClientValidationState(IsValid(data));
        else
            setClientValidationState({});
        setServerValidationState({});
        setData(data);
    }, [IsValid, setData]);

    useEffect(() => {
        setData(undefined);
        get<T>(Source, config => {
            checkAndSetData({ ...config });
            setSavedData({ ...config });
            setServerValidationState({});
        })
    }, [Source, get, checkAndSetData])
    if (!data) return <></>;

    const onSubmit = (item: T) => {
        function fillValidation(validationResponse: IModelState) {
            let validations = {} as IValidationState;
            validationResponse.Validations.forEach(error => {
                validations[error.Key] = error;
            });
            setServerValidationState(validations);
        }

        function post(data: T) {
            postForm<T>(SetConfigUrl, data)
                .then((response) => {
                    if (response?.ModelState?.IsValid && response.Data) {
                        checkAndSetData(response.Data);
                        setSavedData(response.Data);
                        setServerValidationState({});
                        alert("Konfiguration erfolgreich gespeichert.")
                        return true;
                    }
                    if (response?.Data)
                        checkAndSetData(response.Data);

                    if (!response?.ModelState?.IsValid && response?.ModelState) {
                        fillValidation(response?.ModelState);
                    }

                    return false;
                });
        }

        const pre = preSubmit ?? ((i: T) => Promise.resolve(i));
        pre(item).then(newData => post(newData));
    }

    const onAbord = () => {
        checkAndSetData(savedData);
    }

    return <>
        {Title && <h3>{Title}</h3>}
        <DataForm Id={Id}
            dataItem={data}
            onInputChange={(data) => checkAndSetData(data as T)}
            onSubmit={onSubmit}
            onAbort={onAbord}
            Inputs={Inputs}
            validationState={{ ...serverValidationState, ...clientValidationState }} />
    </>
}