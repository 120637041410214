import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns/";
import styled from "styled-components";
import '../styles/MultiSelectInput.css';
import { ErrorText } from "./FeedbackText";

interface IMultiSelectInput {
    label: string;
    data: any[];
    value: any[] | undefined;
    textField: string;
    dataItemKey: string;
    onChange: (values: any[]) => void;
    errorText: string;
}

const StyledMultiSelect = styled(MultiSelect)`
    background-color: var(--color-primary);
    color: var(--color-primary-foreground);
    border: 1px solid var(--color-border);
    border-radius: 5px;

    &span.k-icon {
        color: var(--color-primary-foreground);
    }
    div.k-chip-solid-base {
        color: var(--color-foreground)
        background-color: var(--color-secondary);
        border: 1px solid var(--color-border);
    }
    div.k-chip-solid-base:hover {
        filter: brightness(90%);
    }
`;

const MultiSelectInput: React.FunctionComponent<IMultiSelectInput> = (props) => {

    const onChange = (e: MultiSelectChangeEvent) => {
        props.onChange(e.target.value)
    }

    const { label, ...multiSelectProps } = props;

    return (
        <div className="multiselectinput-container">
            <label className="multiselectinput-label">{props.label}</label>
            <StyledMultiSelect className={props.errorText ? "error" : ""} {...multiSelectProps} onChange={onChange} />
            {props.errorText &&
                <ErrorText text={props.errorText} className="input-invalid-text" />
            }
        </div>
    );
}

export default MultiSelectInput;