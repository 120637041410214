import { useEffect } from "react";
import { useCommunications } from "../services/Communication";

import { useNavigate, useParams } from 'react-router-dom';
import { useConfig } from "../components/ConfigProvider";

export const OneTimeLink = () => {
    let { linkId } = useParams();
    const navigate = useNavigate();
    const config = useConfig();
    const { get } = useCommunications();
    useEffect(() => {
        get("/users/islinkvalid/?linkUid=" + linkId, (resp) => {
            if (resp)
                navigate("/passwordcreation", { state: { linkId: linkId }, replace: true });
            else
                navigate("/", { replace: true });
        })
    }, [linkId, navigate, config, get])

    return <>Prüfen...</>
}