import React, { useState } from "react";
import { useCommunications } from "../services/Communication";

import { usePerm } from "../components/AuthProvider";
import "../styles/Login.css";
import TextInput from "../components/TextInput";
import SubmitButton from "../components/SubmitButton";
import Form from "../components/Form";
import PasswordInput from "../components/PasswordInput";
import { IModelState, IValidationState } from "../components/Form/ManagedForm";
import { ErrorText } from "../components/FeedbackText";
import { useNavigate } from "react-router";
import Button from "../components/Button";
import { HashPassword } from "../util/password";
interface ILogin {
    UserName: string,
    Password: string
}

const Login: React.FunctionComponent = () => {
    const [credentials, setCredentials] = useState<ILogin>({ Password: "", UserName: "" })
    const [validationState, setValidationState] = useState<IValidationState>({});
    const auth = usePerm();
    const { postForm } = useCommunications();

    function fillValidation(validationResponse?: IModelState) {
        if (!validationResponse)
            return;

        let validations = {} as IValidationState;
        validationResponse.Validations.forEach(error => {
            validations[error.Key] = error;
        });
        setValidationState(validations);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const hashedPassword = await HashPassword(credentials.Password);
        postForm<string>("/Login/Login", { UserName: credentials.UserName, Password: hashedPassword } as ILogin).then(data => {
            if (data?.Data)
                auth?.login(data.Data);
            else
                fillValidation(data?.ModelState)
        });
    }

    const handleUserNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ UserName: e.target.value }
        }));
    }

    const handlePasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ Password: e.target.value }
        }));
    }

    const navigate = useNavigate()

    return (
        <div className="login-container">
            <Form onSubmit={handleSubmit} style={{ width: "auto" }} >
                <h1 style={{ alignSelf: "center" }}>Bewerberportal</h1>
                {validationState["Credantials"] &&
                    <ErrorText text={validationState["Credantials"].MessageKey} />
                }
                <TextInput label="Benutzername" value={credentials.UserName} onChange={handleUserNameChanged} errorText={validationState["UserName"]?.MessageKey} />
                <PasswordInput label="Passwort" type="Password" value={credentials.Password} onChange={handlePasswordChanged} errorText={validationState["Password"]?.MessageKey} />
                <SubmitButton color="accent" style={{ marginBottom: "1rem" }}>Anmelden</SubmitButton>
                <Button type="button" onClick={() => navigate("/passwordreset")}>Passwort vergessen</Button>
            </Form>
        </div>
    );
}

export default Login;