import React from 'react'
import { NavLink } from 'react-router-dom'

import '../../styles/Navbar.css';
import { usePerm } from '../AuthProvider';

interface INavbarLinkProps {
    to: string,
    text: string
    icon?: React.ReactElement,
    permission?: string
}

interface INavbarLinkContentProps {
    text: string,
    icon?: React.ReactElement
}

const NavbarLink: React.FunctionComponent<INavbarLinkProps> = (props) => {
    const auth = usePerm();
    if(props.permission)
        if(!auth || !auth.hasAccess(props.permission)) return <></>;
    return (
        <NavLink to={props.to} className='navbar-link'>
            <NavbarLinkContent text={props.text} icon={props.icon} />
        </NavLink>
    );
}

const NavbarLinkContent: React.FunctionComponent<INavbarLinkContentProps> = (props) => {
    return (
        <>
            {props.icon}
            <p className='navbar-link-text'>{props.text}</p>
        </>
    );
}

export default NavbarLink;

export {
    NavbarLinkContent
};

export type {
    INavbarLinkProps
}