import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useConfig } from "../components/ConfigProvider";
import { useCommunications } from "../services/Communication";
import ServerPageView from "./ServerPageView";

interface IApplicationForm {

}

const ApplicationForm: React.FunctionComponent<IApplicationForm> = () => {
    const [css, setCss] = useState<string>();
    const styleRef = useRef<HTMLStyleElement>(null);
    const config = useConfig();
    const { getBase } = useCommunications();

    let { id } = useParams();

    useEffect(() => {
        getBase<string>("/designmanager/style", css => {
            setCss(css);
        });
    }, [id, config, getBase]);

    return (
        <>
            <style ref={styleRef}>
                {css}
            </style>
            <ServerPageView url={`/Form/View?vacancyId=${id}`} />
        </>
    );
}

export default ApplicationForm;