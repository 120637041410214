import deKendoMessages from "../languages/kendo/de-DE.json"
import { loadMessages } from '@progress/kendo-react-intl'

import { IDropdownValue } from "../components/Dropdown";

export default function GetText(id: string): string {
    // const languageCode = localStorage.getItem("languageCode") || navigator.language;

    // let key = id.split(".");

    // let area = TranslationTable.find(x => x.area === key[0]);

    // let item = area?.items.find(x => x.key === key[1]);

    // let translation = item?.translations.find(x => x.languageCode === languageCode);

    // return translation?.text || id;

    return "";
}

interface Language extends IDropdownValue {
    Value: string,
    alphaTwoCode: string,
    title: string
}

export function GetAvailableLanguages(): Array<Language> {
    const availableLanguages: Array<Language> = [
        {
            Value: "de-DE",
            alphaTwoCode: "DE",
            title: "Deutsch"
        },
        {
            Value: "en-US",
            alphaTwoCode: "US",
            title: "English"
        }
    ]

    return availableLanguages;
}

export function GetLanguage(): string {
    return localStorage.getItem("languageCode") || navigator.language;
}

export function GetLocale(): string {
    const languageCode = localStorage.getItem("languageCode") || navigator.language;

    return languageCode.split('-')[0];
}

export function LoadLanguages() {
    loadMessages(deKendoMessages, "de-DE");
}

export type  { Language };