import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSessionStorage } from "../hooks/SessionStorageHook";
import { useCommunications } from "../services/Communication";

export interface IAuthContext {
    sessionKey?: string;
    login: (data: any) => void;
    logout: () => void;
}

export interface IPermission extends IAuthContext {
    hasAccess: (path: string, strict?: boolean) => boolean
}

interface AuthProviderProps {
    children: JSX.Element | JSX.Element[]
}

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: React.FunctionComponent<AuthProviderProps> = (props) => {
    const [sessionKey, setSessionKey] = useSessionStorage("sessionKey", null);

    const navigate = useNavigate();

    const login = useCallback(async (sessionKey: any) => {
        setSessionKey(sessionKey);
        navigate("/vacancies");
    }, [navigate, setSessionKey]);

    // call this function to sign out logged in user
    const logout = useCallback(() => {
        setSessionKey(undefined);
        navigate("/login", { replace: true });
    }, [navigate, setSessionKey]);

    const value = useMemo<IAuthContext>(
        () => ({
            sessionKey,
            login,
            logout
        }),
        [sessionKey, login, logout]
    );

    return <AuthContext.Provider value={value}>
        <PermProvider>{props.children}</PermProvider>
    </AuthContext.Provider>
}

const PermContext = createContext<IPermission | null>(null);
interface PermProviderProps {
    children: JSX.Element | JSX.Element[]
}

const PermProvider = (props: PermProviderProps) => {
    const [permissions, setPermissions] = useState<string[]>([])
    const auth = useAuth();
    const { get } = useCommunications();

    useEffect(() => {
        get<string[]>("/login/getpermissions", (data) => setPermissions(data));
    }, [get])

    const hasAccess = useCallback((perm: string, strict?: boolean) => {
        if (permissions.length === 0) return false;
        if (permissions[0] === "*") return true;
        const endpoint = perm.split(":")[0];
        let allowed = false;

        for (let permission of permissions) {
            if ((!strict && permission.startsWith(endpoint)) || permission === perm) {
                allowed = true;
                break;
            }
        }
        return allowed;
    }, [permissions]);

    return <PermContext.Provider value={{ ...auth!, hasAccess: hasAccess }}>{props.children}</PermContext.Provider>;
}

export const usePerm = () => {
    return useContext(PermContext);
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;