import { useEffect, useState } from "react";
import "../styles/Popup.css";
import IconButton from "./IconButton";

interface IPopup {
    open?: boolean;
    openChanged?(open: boolean): void;
    children?: React.ReactNode;
    width?: string;
    height?: string;
}

const Popup: React.FunctionComponent<IPopup> = (props) => {
    const [open, setOpen] = useState<boolean>(props.open ?? false)

    useEffect(() => {
        if(!props.open)
            return;

        setOpen(props.open);
    }, [props.open])

    useEffect(() => {
        if (!props.openChanged)
            return;

        props.openChanged(open);
    }, [open, props])

    if (!open)
        return <></>

    return (
        <div className="popup">
            <div className="popup-container" style={{width: props.width, height: props.height}}>
                <div className="popup-toolbar">
                    <IconButton icon="close" color="transparent" onClick={() => setOpen(false)} />
                </div>
                <div className="popup-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Popup;