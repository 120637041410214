import FileInput from "../FileInput";
import { IFormInput } from "./FormInput";

interface IFormFileInput extends IFormInput {
    Accept: string;
}

const FormFileInput: React.FunctionComponent<IFormFileInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files;
        if (!file)
            return;

        props.onChange(props, file);

    };

    return (
        <FileInput multiple={true} key={props.Id} id={props.Id.toString()} label={props.Label} onChange={onChange} errorText={props.Validation?.MessageKey} accept={props.Accept} />
    );
}

export type { IFormFileInput }

export default FormFileInput;