import PasswordInput from "../PasswordInput";
import TextInput from "../TextInput";
import { IFormInput } from "./FormInput";

interface IFormTextInput extends IFormInput {
}

const FormTextInput: React.FunctionComponent<IFormTextInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.value);
    };

    return (
        <TextInput
            key={props.Id}
            id={props.Id.toString()}
            value={props.Value ?? ""}
            label={props.Label}
            onChange={onChange}
            errorText={props.Validation?.MessageKey} />
    );
}

const FormPasswordInput: React.FunctionComponent<IFormTextInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.value);
    };

    return (
        <PasswordInput
            key={props.Id}
            id={props.Id.toString()}
            value={props.Value ?? ""}
            label={props.Label}
            onChange={onChange}
            errorText={props.Validation?.MessageKey} />
    );
}

export type { IFormTextInput }
export { FormPasswordInput }
export default FormTextInput;