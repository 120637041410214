const Pdf: React.FunctionComponent = () => {
    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" height={20}>
            <path d="M4.499 19.498L4.499 11.998" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M4.5,12h.75a2.25,2.25,0,0,1,0,4.5H4.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M10.5,19.5a3,3,0,0,0,3-3V15a3,3,0,0,0-3-3Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M16.5,19.5v-6A1.5,1.5,0,0,1,18,12h1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M16.499 16.498L18.749 16.498" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M22.809,5.559a1.5,1.5,0,0,1,.44,1.06V21A2.25,2.25,0,0,1,21,23.248H3A2.25,2.25,0,0,1,.749,21V3A2.25,2.25,0,0,1,3,.748H17.377a1.5,1.5,0,0,1,1.061.439Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M17.249.748v4.5a1.5,1.5,0,0,0,1.5,1.5h4.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
    );
}
 
export default Pdf;