import { useId } from "react";
import "../styles/Checkbox.css";
import { ErrorText } from "./FeedbackText";
import { IInput } from "./Input";

interface ICheckbox extends IInput {
    Link?: string;
}

const Checkbox: React.FunctionComponent<ICheckbox> = (props) => {
    let {
        containerClassName,
        inputClassName,
        labelClassName,
        Link,
        errorText,
        ...inputProps
    } = props;
    let link = props.Link;
    if (link && (!link.startsWith("http") || !link.startsWith("https")))
        link = "https://" + link;

    let id = useId();
    return (<>
        <div className={props.containerClassName ? props.containerClassName : "checkbox-container"}>
            <input id={id} {...inputProps} type="checkbox" className={props.inputClassName ? props.inputClassName : "checkbox"} />
            <label className={props.labelClassName ? props.labelClassName : "checkbox-label"} htmlFor={id}>
                {props.label} {props.Link && <a href={link} target="_blank" rel="noreferrer">Link</a>}
            </label>
        </div>
        {props.errorText && <ErrorText text={props.errorText} className={props.invalidTextClassName ? props.invalidTextClassName : "input-invalid-text"} />}
    </>
    );
}

export default Checkbox;