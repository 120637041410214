import { useState } from "react"

import Themes, { ITheme } from "../theme/Themes";

const hexToRgb = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if(!result)
        return "";

    return `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`;
}

export { hexToRgb };

export const useTheme = () => {
    const setColorVariables = (theme: ITheme) => {
        document.documentElement.style.setProperty('--color-primary', theme.colors.primary)
        document.documentElement.style.setProperty('--color-primary-rgb', hexToRgb(theme.colors.primary))
        document.documentElement.style.setProperty('--color-primary-foreground', theme.colors.primaryForeground)
        document.documentElement.style.setProperty('--color-primary-foreground-rgb', hexToRgb(theme.colors.primaryForeground))
        document.documentElement.style.setProperty('--color-secondary', theme.colors.secondary)
        document.documentElement.style.setProperty('--color-secondary-rgb', hexToRgb(theme.colors.secondary))
        document.documentElement.style.setProperty('--color-border', theme.colors.border)
        document.documentElement.style.setProperty('--color-border-rgb', hexToRgb(theme.colors.border))
        document.documentElement.style.setProperty('--color-accent', theme.colors.accent)
        document.documentElement.style.setProperty('--color-accent-rgb', hexToRgb(theme.colors.accent))
        document.documentElement.style.setProperty('--color-accent-foreground', theme.colors.accentForeground)
        document.documentElement.style.setProperty('--color-accent-foreground-rgb', hexToRgb(theme.colors.accentForeground))
        document.documentElement.style.setProperty('--color-foreground', theme.colors.foreground)
        document.documentElement.style.setProperty('--color-foreground-rgb', hexToRgb(theme.colors.foreground))
        document.documentElement.style.setProperty('--color-background', theme.colors.background)
        document.documentElement.style.setProperty('--color-background-rgb', hexToRgb(theme.colors.background))
        document.documentElement.style.setProperty('--color-shadow', theme.colors.shadow)
        document.documentElement.style.setProperty('--color-shadow-rgb', hexToRgb(theme.colors.shadow))
        document.documentElement.style.setProperty('--color-error', theme.colors.error)
        document.documentElement.style.setProperty('--color-error-rgb', hexToRgb(theme.colors.error))
        document.documentElement.style.setProperty('--color-error-foreground', theme.colors.errorForeground)
        document.documentElement.style.setProperty('--color-error-foreground-rgb', hexToRgb(theme.colors.errorForeground))
    };

    let selectedThemeValue = localStorage.getItem("theme");

    if (!selectedThemeValue)
        selectedThemeValue = Themes[0].value;

    let selectedTheme = Themes.find(x => x.value === selectedThemeValue);

    setColorVariables(selectedTheme as ITheme);

    const [theme, setTheme] = useState(selectedTheme);

    const setThemeValue = (themeValue: string) => {
        localStorage.setItem("theme", themeValue);

        let selectedTheme = Themes.find(x => x.value === themeValue);

        if (selectedTheme) {
            setTheme(selectedTheme);
            setColorVariables(selectedTheme);
        }
    };

    return { theme, setThemeValue, setColorVariables }
}