import React from 'react';

import GetText from '../services/Localization';

import "../styles/NotFound.css";

const NotFound: React.FunctionComponent = () => {
    return (
        <div className="notfound-container">
            <p className="notfound-text">404</p>
            <p className="notfound-text">{GetText("error.notfound")}</p>
        </div>
    );
}

export default NotFound;