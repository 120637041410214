interface ITheme {
    value: string,
    name: string,
    colors: IColors
}

interface IColors {
    [key: string]: string;
    primary: string;
    primaryForeground: string;
    secondary: string;
    border: string;
    accent: string;
    accentForeground: string;
    foreground: string;
    background: string;
    shadow: string;
    error: string;
    errorForeground: string;
}

const Themes: ITheme[] = [
    {
        value: "light",
        name: "Light",
        colors: {
            primary: "#ffffff",
            primaryForeground: "#000000",
            secondary: "#e3e3e3",
            border: "#c7c7c7",
            accent: "#ff7900",
            accentForeground: "#ffffff",
            foreground: "#000000",
            background: "#f7f7f7",
            shadow: "#c3c3c3",
            error: "#e83a3a",
            errorForeground: "#ffffff"
        }
    },
    {
        value: "dark",
        name: "Dark",
        colors: {
            primary: "#282c34",
            primaryForeground: "#ffffff",
            secondary: "#525252",
            border: "#5e5e5e",
            accent: "#ff7900",
            accentForeground: "#ffffff",
            foreground: "#ffffff",
            background: "#343a45",
            shadow: "#000000",
            error: "#e83a3a",
            errorForeground: "#ffffff"
        }
    }
]

export default Themes;

export type { ITheme, IColors };