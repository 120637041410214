import React from 'react';
import Navbar from './Navbar/Navbar';

import "../styles/Layout.css";
import { Navigate, Outlet } from 'react-router';
import { usePerm } from './AuthProvider';
import { useLicense } from './LicenseProvider';
import NoValidLicense from './NoValidLicense';

const Layout: React.FunctionComponent = () => {
    const auth = usePerm();
    const license = useLicense();

    if (!auth?.sessionKey) {
        return <Navigate to="/login" />;
    }

    return (
        <div className='layout-grid'>
            <Navbar />
            <div className='layout-content'>
                {!license?.IsActive &&
                    <NoValidLicense />
                }
                <Outlet />
            </div>
        </div>
    );
}

export default Layout
