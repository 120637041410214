import Input, { IInput } from "./Input";

interface INumberInput extends IInput {
    
}
 
const NumberInput: React.FunctionComponent<INumberInput> = (props) => {
    return (
        <Input {...props} type="number" />
    );
}
 
export default NumberInput;