const Checkbox: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height={20}>
            <g>
                <rect x="0.5" y="0.5" width="13" height="13" rx="3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></rect>
                <path d="M4,8,6.05,9.64a.48.48,0,0,0,.4.1.5.5,0,0,0,.34-.24L10,4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            </g>
        </svg>
    );
}

export default Checkbox;