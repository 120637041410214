import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ProseMirror, EditorTools } from '@progress/kendo-react-editor';
import { KeyValuePair } from "../../types/KeyValuePair";
const { Plugin, PluginKey, Decoration, DecorationSet } = ProseMirror;

type FieldInfoToolState = {
    active: boolean;
    hints: FieldInfoHints
}

export type FieldInfoHints = [{ regx: string, fields: KeyValuePair[], isEqual: (matched: string, key: string) => boolean }]


export const fieldInfoKey = new PluginKey<FieldInfoToolState>('fieldinfo');

export function fieldInfo(hints: FieldInfoHints) {
    return new Plugin({
        key: fieldInfoKey,
        state: {
            init: () => ({ active: false, hints: hints }),
            apply: (tr, value) => {
                const next = tr.getMeta(fieldInfoKey);
                if (next !== undefined && next !== value.active) {
                    return { ...value, active: next };
                }
                return value;
            },
        },
        props: {
            decorations: (state) => {
                const { doc } = state;

                const decorations: any[] = [];
                const pluginState = fieldInfoKey.getState(state);
                if (pluginState && pluginState.active) {
                    doc.nodesBetween(0, doc.content.size, (node, pos) => {
                        if (node.isText && node.type.name === 'text') {
                            hints.forEach(hint => {
                                if (!node.text)
                                    return;
                                const regx = new RegExp(hint.regx, 'dg');
                                let match: RegExpExecArray | null;
                                while ((match = regx.exec(node.text)) != null) {
                                    let number = match[0].replace("{", "").replace("}", "");
                                    let fieldInfo = hint.fields.find(x => hint.isEqual(number, x.Key));
                                    if (!fieldInfo) continue;
                                    decorations.push(
                                        Decoration.inline(pos - 1 + match.index, pos - 1 + match.index + match[0].length, {
                                            'data-suffix': ':' + fieldInfo.Value,
                                            nodeName: 'span',
                                        })
                                    );
                                    if (!regx.global) break;
                                }
                            })
                        }
                    });
                }
                return DecorationSet.create(doc, decorations);
            },
        },
    });
}

const onDownPreventDefault = {
    onMouseDown: (e: any) => e.preventDefault(),
    onPointerDown: (e: any) => e.preventDefault(),
};

export const FieldInfoTool = (props: EditorTools.InlineFormatToolProps) => {
    const { view, render, ...buttonProps } = props;
    const state = view && view.state;
    const toolState = Boolean(state && fieldInfoKey.getState(state)?.active);
    const onClick = React.useCallback(() => {
        if (view) {
            view.dispatch(view.state.tr.setMeta(fieldInfoKey, !toolState));
        }
    }, [toolState, view]);

    return (
        <Button
            onClick={onClick}
            togglable={true}
            selected={toolState}
            icon="information"
            title={'Hinweise anzeigen'}
            {...onDownPreventDefault}
            {...buttonProps}
        />
    );
};

export const fieldInfoStyles = `
  span[data-suffix]:after {
    content: attr(data-suffix);
    color: rgb(0, 0, 0, 0.5);
    cursor: text;
    font-style: italic;
  }
`;
