import React from "react";
import CopyInput from "../components/CopyInput";
import DataGrid from "../components/DataGrid";
import { FORM_TYPE } from "../components/Form/FormInput";
import { IFormResult } from "../components/Form/ManagedForm";
import Popup from "../components/Popup";
import { useCommunications } from "../services/Communication";


interface IApiUser {
    Id: string | undefined;
    User: string | undefined;
    TimeStamp: string | undefined;
    ExpiryDate: Date | null;
    LastAccess: string | null;
    Token: string | undefined;
}

const ApiConfiguration: React.FunctionComponent = () => {
    const [token, setToken] = React.useState<string>("");
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const { post, postForm } = useCommunications();

    const revokeApiUser = (apiUser: IApiUser) => {
        post<IApiUser>("/apiconfiguration/revoke", apiUser)
        return true;
    }

    const createApiUser = async (apiUser: IApiUser) => {
        let response = await postForm<IApiUser>("/apiconfiguration/create", apiUser);
        if (response?.ModelState?.IsValid) {
            setToken((response.Data as IApiUser).Token as string);
            setOpenPopup(true);
            (response.Data as IApiUser).Token = "";
        }
        return response as IFormResult<IApiUser>;
    }

    const handlePopupChange = (e: boolean) => {
        setOpenPopup(e);
        if (!e) {
            setToken("");
        }
    }

    return (<div style={{ color: "var(--color-foreground)" }}>
        <Popup open={openPopup} openChanged={handlePopupChange}>
            <h1>API-Token</h1>
            <CopyInput value={token} />
        </Popup>
        <DataGrid<IApiUser>
            RowDefinition={{
                DataItemKey: "Id",
                ColumnDefinitions: [
                    { Field: "User", Title: "Benutzer" },
                    { Field: "TimeStamp", Title: "Erstelldatum" },
                    { Field: "ExpiryDate", Title: "Ablaufdatum" },
                    { Field: "LastAccess", Title: "Letzter Zugriff" },
                ],
                ButtonDefinitions: {
                    onDelete: revokeApiUser,
                    onDeleteText: "Revoke",
                    onCreate: createApiUser,
                    editForm: [
                        { Id: "User", Label: "User", Discriminator: FORM_TYPE.Text },
                        { Id: "ExpiryDate", Label: "Ablaufdatum", Default: undefined, Discriminator: FORM_TYPE.DateInput }
                    ]
                }
            }}
            DataSource={"/apiconfiguration/getall"}
            Discriminator="" />
    </div>);
}

export default ApiConfiguration;