import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';

import '../../styles/Navbar.css';

import NavbarItems from '../../services/NavbarService';
import { useCommunications } from "../../services/Communication";
import { usePerm } from '../AuthProvider';
import IconButton from '../IconButton';
import Popup from '../Popup';
import packageJson from '../../../package.json';

const Navbar: React.FunctionComponent = () => {
    const [isOpen, setOpen] = useState(false);
    const [width, setWidth] = useState(0);
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const { get } = useCommunications();

    const auth = usePerm();

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entires) => {
            setWidth(entires[0].contentRect.width)
        })

        resizeObserver.observe(document.getElementById("root") as HTMLElement)
    }, [setWidth])

    const location = useLocation()

    useEffect(() => {
        setOpen(false);
    }, [location])

    function onToggleClicked() {
        setOpen(!isOpen);
    }

    function onLogoutClicked() {
        get("/Login/Logout", () => { });
        auth?.logout();
    }

    function onHeadingClicked() {
        navigate("/");
    }
    const handlePopupChange = (e: boolean) => {
        setOpenPopup(e);
    }
    return (
        <nav className='navbar'>
            <div className='navbar-toggle-container'>
                <button className='btn btn-primary' onClick={onToggleClicked}><span className='k-icon k-i-menu'></span></button>
            </div>
            <div className='navbar-content-wrapper' style={{ display: !isOpen && width <= 600 ? 'none' : 'block' }}>
                <div className='navbar-content'>
                    <div className='navbar-logo'>
                        <h1 onClick={onHeadingClicked} className='navbar-heading'>Bewerberportal</h1>
                    </div>
                    <div className='navbar-delimiter-line'></div>
                    <div className='navbar-profile'>
                        {/* <LanguageSwitch /> */}
                        <IconButton color="accent" icon='signOut' onClick={onLogoutClicked}>Abmelden</IconButton>
                    </div>
                    <NavbarItems />

                </div>
            </div>
            <div style={{ marginLeft: "10px", marginTop: "auto" }}>
                <h5 onClick={() => setOpenPopup(true)}>© adata</h5>
                <Popup open={openPopup} openChanged={handlePopupChange} width="fit-content" height="fit-content">
                    <div>
                        <b>adata Software GmbH</b>
                    </div>
                    <div>
                        <p>Copyright © 2020-2022 adata Software GmbH</p>
                    </div>
                    <p>
                        Dieses Programmpaket enthält Software, die Eigentum der Progress Software Corp. ist.
                    </p>
                    <div>
                        Client Version: {packageJson.version}
                    </div>
                    <div>
                        Server Version: <ServerVersion />
                    </div>

                </Popup>
            </div>
        </nav>
    );
};

export default Navbar


const ServerVersion = () => {
    const [version, setVersion] = useState("...");
    const { get } = useCommunications();

    useEffect(() => {
        get<string>("/Version/ServerVersion", version => setVersion(version))
    }, [get])
    return <span>{version}</span>;
}