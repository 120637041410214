import { Draggable, DropTarget } from "../views/Draggable";
import { FieldIcon, IField, IVacancyField } from "../views/Vacancies";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Icon from "./Icon";
import IconButton from "./IconButton";
import "../styles/FieldSelection.css";

interface IFieldSelection {
    titel: string,
    fieldDropType: string,
    selectedFields: IVacancyField[],
    possibleFields: IField[],
    toggleRequired: (fieldId: number) => void,
    handleDrop: (dragIndex: number, hoverIndex: number, newFieldId: number | string) => void,
    removeField: (id: number) => void,
    onDelete?: (field: IField) => Promise<void>,
    onEdit?: (field: IField) => Promise<void>,
    onAdd?: () => void
}

export default function FieldSelection(props: IFieldSelection) {
    return (
        <>
            <h2 style={{ marginBottom: "0.5rem" }}>{props.titel}</h2>
            {props.onAdd && <Button color="accent" onClick={() => props.onAdd ? props.onAdd() : {}}>Hinzufügen</Button>}
            <div className="fields-container">
                <div className="fields">
                    <h3>Nicht zugeordnet</h3>
                    <div className="drag-container">
                        <AddField
                            possibleFields={props.possibleFields.filter(x => !props.selectedFields.find(f => f.FieldId === x.Id))}
                            itemType={props.fieldDropType}
                            onDelete={props.onDelete}
                            onEdit={props.onEdit}
                        />
                    </div>
                </div>
                <div className="fields">
                    <h3>Zugeordnet</h3>
                    <div className="drag-container">
                        {props.selectedFields.map((field, index) => {
                            const correspondingField = props.possibleFields.find(pf => field.FieldId === pf.Id);
                            if (correspondingField?.Required && !field.Required)
                                props.toggleRequired(field.FieldId);
                            return (
                                <Draggable itemType={props.fieldDropType} key={`${field.FieldId}_${index}}`}
                                    dragItem={{ id: field.FieldId, index }}
                                    moveItem={props.handleDrop} >
                                    <div className="draggable">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <FieldIcon fieldType={parseInt(correspondingField?.Type ?? "-1")} />
                                            <b style={{ marginLeft: "1rem" }}><span>{correspondingField?.Name}</span></b>&nbsp;<span className="subtle">{correspondingField?.Id}</span>
                                        </div>
                                        <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                                            <Checkbox label="Pflichtfeld" disabled={correspondingField?.Required} checked={field.Required} onChange={() => props.toggleRequired(field.FieldId)} />
                                            <IconButton icon="delete" onClick={() => props.removeField(field.FieldId)} style={{ marginLeft: "1rem" }} />
                                            <Icon icon="drag" style={{ marginLeft: "1rem" }} />
                                        </div>
                                    </div>
                                </Draggable>)
                        }
                        )}
                        <DropTarget key={"lastelement_" + props.selectedFields.length} acceptItemType={props.fieldDropType} dragItem={{ id: "invisibleLast", index: props.selectedFields.length }} moveRow={props.handleDrop} className="draggable empty-last">
                            <span className="k-icon k-i-plus" />
                        </DropTarget>
                    </div>
                </div>
            </div>
        </>
    );
}

interface IAddFieldProps {
    possibleFields: Array<IField>,
    onDelete?: (field: IField) => void;
    onEdit?: (field: IField) => void;
    itemType: string;
}


export const AddField = (props: IAddFieldProps) => {
    if (props.possibleFields.length === 0)
        return <h4 style={{ marginLeft: "1rem" }}>Alle Felder hinzugefügt</h4>

    return <>
        {props.possibleFields.map(x =>
            <Draggable itemType={props.itemType} dragItem={{ id: x.Id, index: -1 }} key={x.Id}>
                <div className="draggable">
                    <FieldIcon fieldType={parseInt(x.Type)} />
                    <b style={{ marginLeft: "1rem" }}>{x.Name}</b>&nbsp;<span className="subtle">{x.Id}</span>
                    <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                        {props.onEdit &&
                            <IconButton icon="edit" onClick={() => props.onEdit?.(x)} style={{ marginLeft: "auto" }} />
                        }
                        {props.onDelete &&
                            <IconButton icon="delete" onClick={() => props.onDelete?.(x)} style={{ marginLeft: "1rem" }} />
                        }
                        <Icon icon="drag" style={{ marginLeft: "1rem" }} />
                    </div>
                </div>
            </Draggable>
        )}
    </>
}