import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns/";
import React from "react";
import { IFormInput } from "./FormInput";

interface Item {
    Text: string,
    Value: string | number
}

interface IFormMultiSelectInput<T> extends IFormInput<T> {
    Items: Item[]
}

const FormMultiSelectInput: React.FunctionComponent<IFormMultiSelectInput<any>> = (props) => {
    const onChange = (event: MultiSelectChangeEvent) => {
        if (props.onChange)
            props.onChange(props, event.target.value.map(x => x.Value));
    };
    const values = props.Value ? props.Items.filter(x => props.Value.includes(x.Value)) : [];

    return (
        <div className="input-container" >
            <div className="form-input">
                {props.Label &&
                    <label className="input-label">{props.Label}</label>
                }
                <MultiSelect data={props.Items} textField={"Text"} onChange={onChange} value={values} />
            </div>
        </div>);
}

export type { IFormMultiSelectInput }

export default FormMultiSelectInput;