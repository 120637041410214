import Input, { IInput } from "./Input";

interface ITextInput extends IInput {

}

const TextInput: React.FunctionComponent<ITextInput> = (props) => {
    return (
        <Input {...props} type="text" />
    );
}

export default TextInput;