import Input from "../Input";
import { IFormInput } from "./FormInput";

interface IFormDateInput extends IFormInput {
    type: React.HTMLInputTypeAttribute
}

const FormDateInput: React.FunctionComponent<IFormDateInput> = (props) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props, event.target.value);
    };

    return (
        <Input type={"date"} key={props.Id} defaultValue={props.Default} id={props.Id.toString()} label={props.Label} onChange={onChange} errorText={props.Validation?.MessageKey} />
        // <div>

        // </div>
        // <div className="form-input">
        //     <FormValidation {...props.Validation}></FormValidation>
        //     <Tooltip text={props.UiHint}>
        //         <input type={props.type} id={props.Id} name={props.Id} hidden={props.Hidden} value={props.Value} onChange={onChange} />
        //     </Tooltip>
        // </div>
    );
}

export type { IFormDateInput }

export default FormDateInput;