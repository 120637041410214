const List: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5px" height={20}>
            <g>
                <line x1="8.25" y1="3" x2="23.25" y2="3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="8.25" y1="12" x2="23.25" y2="12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="8.25" y1="21" x2="23.25" y2="21" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                <path d="M.8,18.36a1.5,1.5,0,1,1,1.45,1.89A1.5,1.5,0,1,1,.8,22.15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M.75,11.25a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a2.16,2.16,0,0,1-.47,1.34L.75,14.25h3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
                <g>
                    <path d="M.75.75H1.5a.76.76,0,0,1,.75.75V6.75" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
                    <line x1="0.75" y1="6.75" x2="3.75" y2="6.75" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                </g>
            </g>
        </svg>
    );
}

export default List;