import { GridCellProps } from "@progress/kendo-react-grid";
import Button from "./Button";

interface ICommandCellDefinition<T> extends GridCellProps {
    onCustomText?: string,
    hasCustom: boolean,
    onCustom?: (dataItem: T) => void,
    onDeleteText?: string,
    hasDelete: boolean,
    onDelete?: (dataItem: T) => void,
    onEditText?: string,
    hasEdit: boolean
    onEdit?: (dataItem: T) => void
}

function DataGridButtonCell<T>(props: ICommandCellDefinition<T>) {

    const onEditClick = () => {
        if (props.onEdit)
            props.onEdit(props.dataItem)
    }

    const onDeleteClick = () => {
        if (props.onDelete)
            props.onDelete(props.dataItem)
    }

    const onCustomClick = () => {
        if (props.onCustom)
            props.onCustom(props.dataItem)
    }

    return (
        <td>
            {props.hasEdit &&
                <Button color="accent" onClick={onEditClick}>{props.onEditText ?? "Bearbeiten"}</Button>
            }
            {props.hasDelete &&
                <Button color="accent" onClick={onDeleteClick}>{props.onDeleteText ?? "Löschen"}</Button>
            }
            {props.hasCustom &&
                <Button color="accent" onClick={onCustomClick}>{props.onCustomText ?? "Custom"}</Button>
            }
        </td>
    );
}

export type { ICommandCellDefinition }

export default DataGridButtonCell;