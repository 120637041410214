const Numeriacal: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height={20}>
            <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M2.94899 13.4165H5.71189"></path>
            <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M8.32814 5.26367H11.091"></path>
            <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M2.90895 9.5901L4.38019 8.70703V13.4167"></path>
            <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M8.28812 1.43727L9.75935 0.554199V5.2639"></path>
            <rect width="2.708" height="4.848" x="8.288" y="8.652" stroke="#000000" strokeLinejoin="round" rx="1.354"></rect>
            <rect width="2.708" height="4.848" x="2.909" y=".5" stroke="#000000" strokeLinejoin="round" rx="1.354"></rect>
        </svg>
    );
}

export default Numeriacal;