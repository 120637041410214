import { useEffect, useRef } from "react";
import Checkbox from "../components/Checkbox";
import Dropdown, { IDropdownValue } from "../components/Dropdown";
import EmailInput from "../components/EmailInput";
import FileInput from "../components/FileInput";
import Form from "../components/Form";
import NumberInput from "../components/NumberInput";
import SubmitButton from "../components/SubmitButton";
import TextInput from "../components/TextInput";

interface IFormPreview {
    style: string;
}

const FormPreview: React.FunctionComponent<IFormPreview> = (props) => {
    const styleRef = useRef<HTMLStyleElement>(null);

    useEffect(() => {
        if (!props.style)
            return;

        styleRef.current!.innerHTML = props.style;
    }, [props.style])

    interface ITestData extends IDropdownValue {
        title: string;
    }

    const selectedData = { title: "Element 1", Value: "1" } as ITestData;

    const data = [
        { title: "Element", Value: "0" },
        selectedData,
        { title: "Element 2", Value: "2" }
    ] as ITestData[];

    const itemTemplate = (e: ITestData | undefined) => {
        return <p style={{margin: 0, width: "100px"}}>
            {e?.title}
        </p>
    }

    return (
        <div style={{ marginTop: "1rem", width: "100%" }} id="previewContainer">
            <style ref={styleRef}></style>
            <h1 style={{marginTop: 0}}>Vorschau</h1>
            <div style={{ width: "100%", marginTop: "1rem" }}>
                <Form className="custom-form">
                    <div style={{ display: "flex", flexFlow: "row", gap: "1rem" }}>
                        <div style={{ width: "50%", display: "flex", flexFlow: "column" }}>
                            <h3 style={{ margin: 0 }}>Normal</h3>
                            <TextInput label="Text" containerClassName="custom-input-container" labelClassName="custom-input-label" inputClassName="custom-input" />
                            <EmailInput label="E-Mail" containerClassName="custom-input-container" labelClassName="custom-input-label" inputClassName="custom-input" />
                            <NumberInput label="Nummer" containerClassName="custom-input-container" labelClassName="custom-input-label" inputClassName="custom-input" />
                            <FileInput label="Nummer" containerClassName="custom-input-container" labelClassName="custom-input-label" inputClassName="custom-input" />
                            <Dropdown label="Datei" data={data} value={selectedData} itemTemplate={e => itemTemplate(e)} onItemClicked={() => { }} />
                            <Checkbox label="Checkbox" containerClassName="custom-checkbox-container" labelClassName="custom-checkbox-label" inputClassName="custom-checkbox" />
                        </div>
                        <div style={{ width: "50%", display: "flex", flexFlow: "column" }}>
                            <h3 style={{ margin: 0 }}>Fehler</h3>
                            <TextInput label="Text" errorText="Text - Fehler"
                                containerClassName="custom-input-container"
                                labelClassName="custom-input-label"
                                inputClassName="custom-input"
                                invalidClassName="custom-input-invalid"
                                invalidTextClassName="custom-input-invalid-text" />
                            <EmailInput label="E-Mail" errorText="E-Mail - Fehler"
                                containerClassName="custom-input-container"
                                labelClassName="custom-input-label"
                                inputClassName="custom-input"
                                invalidClassName="custom-input-invalid"
                                invalidTextClassName="custom-input-invalid-text" />
                            <NumberInput label="Nummer" errorText="Nummer - Fehler"
                                containerClassName="custom-input-container"
                                labelClassName="custom-input-label"
                                inputClassName="custom-input"
                                invalidClassName="custom-input-invalid"
                                invalidTextClassName="custom-input-invalid-text" />
                            <FileInput label="Datei" errorText="Datei - Fehler"
                                containerClassName="custom-input-container"
                                labelClassName="custom-input-label"
                                inputClassName="custom-input"
                                invalidClassName="custom-input-invalid"
                                invalidTextClassName="custom-input-invalid-text" />
                            <Dropdown label="Auswahl" errorText="Auswahl - Fehler" data={data} value={selectedData} itemTemplate={e => itemTemplate(e)} onItemClicked={() => { }}
                                containerClassName="custom-dropdown-container"
                                labelClassName="custom-dropdown-label"
                                dropdownClassName="custom-dropdown"
                                dropdownPickerClassName="custom-dropdown-picker"
                                dropdownValueClassName="custom-dropdown-value"
                                dropdownIconClassName="custom-dropdown-icon"
                                dropdownIconFlippedClassName="custom-dropdown-icon-flipped"
                                dropdownContentClassName="custom-dropdown-content"
                                dropdownContentOpenClassName="custom-dropdown-content-open"
                                dropdownItemClassName="custom-dropdown-item"
                                dropdownItemSelectedClassName="custom-dropdown-item-selected"
                                dropdownInvalidClassName="custom-dropdown-invalid"
                                dropdownInvalidTextClassName="custom-dropdown-invalid-text"/>
                        </div>
                    </div>
                    <SubmitButton onClick={(e) => e.preventDefault()} className="custom-btn">Absenden</SubmitButton>
                </Form>
            </div>
        </div >
    );
}

export default FormPreview;