import React from 'react';
import '../../styles/Navbar.css';

interface INavbarSectionProps {
    text?: string,
    children: React.ReactNode
}

const NavbarSection: React.FunctionComponent<INavbarSectionProps> = (props) => {
    return (
        <div className='navbar-delimiter'>
            <p className='navbar-delimiter-text'>{props.text}</p>
            <div className='navbar-delimiter-line'></div>
            {props.children}
        </div>
    );
}

export default NavbarSection;