import { useEffect, useState } from "react";
import "../styles/Window.css";
import IconButton from "./IconButton";

interface IWindow {
    openChanged?: (isOpen: boolean) => void;
    windowClosedStyle?: React.CSSProperties;
    toolbarTemplate?: React.ReactNode | React.ReactNode[];
    children?: React.ReactNode | React.ReactNode[];
}

const Window: React.FunctionComponent<IWindow> = (props) => {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!props.openChanged)
            return;

        props.openChanged(open);
    }, [open, props]);

    return (
        <div className={`window ${open ? "window-open" : ""}`} style={!open ? props.windowClosedStyle : {}}>
            <div className="window-toolbar">
                <div style={{marginLeft: "0.5rem"}}>
                    {props.toolbarTemplate}
                </div>
                <IconButton id="windowAction" color="transparent" icon={open ? "minimize" : "maximize"} onClick={() => { setOpen(!open) }} style={{marginLeft: "auto"}} />
            </div>
            <div className="window-content">
                {props.children}
            </div>
        </div>
    );
}

export default Window;