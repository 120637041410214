import { useEffect, useState } from "react";
import { useCommunications } from "../../services/Communication";
import { IComponent } from "../Component";
import FormInput, { IFormInput } from "./FormInput";
import Form from "../Form";
import SubmitButton from "../SubmitButton";
import { ErrorText } from "../FeedbackText";
import { IFormValidation } from "./FormValidation";

interface IManagedForm extends IComponent {
    Id: string;
    Title: string;
    DataSource: string;
    Inputs: IFormInput<IFormData>[];
    PostUrl: string;
}

interface IFormData {
    [id: string]: any
}

interface IFormResult<T> {
    Data?: T;
    ModelState?: IModelState;
}

interface IModelState {
    IsValid: boolean;
    Validations: IFormValidation[]
}

interface IValidationState {
    [id: string | number | symbol]: IFormValidation
}

const ManagedForm: React.FunctionComponent<IManagedForm> = (props) => {
    const [data, setData] = useState<IFormData>({});
    const [validationState, setValidationState] = useState<IValidationState>({});
    const { get, postForm } = useCommunications();

    useEffect(() => {
        if (props.DataSource) {
            get<IFormData>(props.DataSource, formData => {
                setData(formData);
            });
        }
    }, [setData, props.DataSource, get])

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        postForm(props.PostUrl, toFormData(data)).then(response => {
            if (!response)
                return;

            if (!response?.Data)
                fillValidation(response!.ModelState!);
        });
    }

    function isFileList(obj: any): obj is FileList {
        return obj instanceof FileList;
    }


    function toFormData(data: any) {
        let formData = new FormData();
        for (let key in data) {
            let currData = data[key];
            if (Array.isArray(currData))
                currData.forEach((d: any) => {
                    formData.append(key, d);
                })
            else if (isFileList(currData)) {
                for (let i = 0; i < currData.length; i++) {
                    var file = currData.item(i);
                    if (file)
                        formData.append(key, file)
                }
            }
            else
                formData.append(key, data[key]);
        }
        return formData;
    }

    function fillValidation(validationResponse: IModelState) {
        let validations = {} as IValidationState;
        validationResponse.Validations.forEach(error => {
            validations[error.Key] = error;
        });
        setValidationState(validations);
    }

    function onInputChange(formInput: IFormInput<IFormData>, value: any) {
        let formData = { ...data };
        formData[formInput.Id] = value;
        setData(formData);
    }

    return (
        <Form
            id={props.Id}
            onSubmit={handleSubmit}>
            {props.Title &&
                <h2>{props.Title}</h2>
            }
            <ErrorText text={validationState[props.Id]?.MessageKey} />
            {props.Inputs.map((input) => {
                return <FormInput key={input.Id} {...input} Value={data[input.Id]} Validation={validationState[input.Id]} onChange={onInputChange} />
            })}
            {props.PostUrl && <SubmitButton color="accent">Absenden</SubmitButton>}

        </Form>
    );
}

export type { IManagedForm, IFormResult, IModelState, IValidationState }

export default ManagedForm;