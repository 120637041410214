import {
  Routes,
  Route
} from "react-router-dom";

import Layout from './components/Layout';

import * as Localization from './services/Localization';

import "@progress/kendo-theme-bootstrap";

import 'react-toastify/dist/ReactToastify.min.css';
import './styles/Button.css';
import './styles/App.css';

import { useTheme } from "./hooks/ThemeHook";

import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import Login from "./views/Login";
import Users from "./views/Users";
import ApplicationForm from "./views/ApplicationForm";
import DesignManager from "./views/DesignManager";
import NotFound from "./views/NotFound";
import AuthProvider from "./components/AuthProvider";
import Vacancys from "./views/Vacancies";
import CustomRouter from "./components/CustomRouter";
import ApplicationSubmitted from "./views/ApplicationSubmitted";
import ApiConfiguration from "./views/ApiConfiguration";
import { OneTimeLink } from "./views/OneTimeLink";
import { PasswordCreation } from "./views/PasswordCreation";
import PasswordReset from "./views/PasswordReset";
import Configuration from "./views/Configuration";
import { ConfigProvider } from "./components/ConfigProvider";
import DefaultFields from "./views/DefaultFields";
import { LicenseProvider } from "./components/LicenseProvider";
import UserProfile from "./views/UserProfile";


function App() {
  const languageCode = localStorage.getItem("languageCode");

  if (!languageCode)
    localStorage.setItem("languageCode", "");

  Localization.LoadLanguages();

  useTheme();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entires) => {
      setWidth(entires[0].contentRect.width)
    })

    resizeObserver.observe(document.getElementById("root") as HTMLElement)
  }, [setWidth])

  function closeButton() {
    return (
      <span className="k-icon k-i-close" style={{ fontSize: "1.5rem" }}></span>
    );
  }

  return (
    <CustomRouter>
      <ConfigProvider>
        <AuthProvider>
          <LicenseProvider>
            <Routes>
              <Route>
                <Route path="/login" element={<Login />} />
                <Route path="/applicationform/:id" element={<ApplicationForm />} />
                <Route path="/applicationsubmitted" element={<ApplicationSubmitted />} />
                <Route path="/onetimelink/:linkId" element={<OneTimeLink />} />
                <Route path="/passwordcreation" element={<PasswordCreation />} />
                <Route path="/passwordreset" element={<PasswordReset />} />
              </Route>
              <Route element={<Layout />} >
                <Route path="/*" element={<Vacancys />} />
                <Route path="/vacancies/:id" element={<Vacancys />} />
                <Route path="/defaultfields" element={<DefaultFields />} />
                <Route path="/users" element={<Users />} />
                <Route path="/designmanager" element={<DesignManager />} />
                <Route path="/apiconfiguration" element={<ApiConfiguration />} />
                <Route path="/config" element={<Configuration />} />
                <Route path="/userprofile" element={<UserProfile />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
            <ToastContainer position={width > 600 ? "bottom-right" : "top-center"} closeButton={closeButton} theme="colored" />
          </LicenseProvider>
        </AuthProvider>
      </ConfigProvider>
    </CustomRouter>
  );
}

export default App;
