import IconProps from "./IconProps";

const Drag: React.FunctionComponent<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" height={20} style={props.style}>
            <path stroke="#000000" d="M10.2691 3.34615C10.2691 4.77997 9.10679 5.94231 7.67297 5.94231C6.23915 5.94231 5.07682 4.77997 5.07682 3.34615C5.07682 1.91234 6.23915 0.75 7.67297 0.75C9.10679 0.75 10.2691 1.91234 10.2691 3.34615Z"></path>
            <path stroke="#000000" d="M10.2691 12C10.2691 13.4338 9.10679 14.5961 7.67297 14.5961C6.23915 14.5961 5.07682 13.4338 5.07682 12C5.07682 10.5661 6.23915 9.40381 7.67297 9.40381C9.10679 9.40381 10.2691 10.5661 10.2691 12Z"></path>
            <path stroke="#000000" d="M10.2691 20.6538C10.2691 22.0876 9.10679 23.2499 7.67297 23.2499C6.23915 23.2499 5.07682 22.0876 5.07682 20.6538C5.07682 19.22 6.23915 18.0576 7.67297 18.0576C9.10679 18.0576 10.2691 19.22 10.2691 20.6538Z"></path>
            <path stroke="#000000" d="M18.9232 3.34615C18.9232 4.77997 17.7609 5.94231 16.327 5.94231C14.8932 5.94231 13.7309 4.77997 13.7309 3.34615C13.7309 1.91234 14.8932 0.75 16.327 0.75C17.7609 0.75 18.9232 1.91234 18.9232 3.34615Z"></path>
            <path stroke="#000000" d="M18.9232 12C18.9232 13.4338 17.7609 14.5961 16.327 14.5961C14.8932 14.5961 13.7309 13.4338 13.7309 12C13.7309 10.5661 14.8932 9.40381 16.327 9.40381C17.7609 9.40381 18.9232 10.5661 18.9232 12Z"></path>
            <path stroke="#000000" d="M18.9232 20.6538C18.9232 22.0876 17.7609 23.2499 16.327 23.2499C14.8932 23.2499 13.7309 22.0876 13.7309 20.6538C13.7309 19.22 14.8932 18.0576 16.327 18.0576C17.7609 18.0576 18.9232 19.22 18.9232 20.6538Z"></path>
        </svg>
    );
}

export default Drag;