const Letters: React.FunctionComponent = () => {
    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" height={20}>
            <path d="M0.75 12.749L11.25 12.749" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15.75 17.249L23.25 17.249" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15.75,21.749V13.5a3.75,3.75,0,0,1,7.5,0v8.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M.75,21.749V7.5a5.25,5.25,0,0,1,10.5,0v14.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    );
}

export default Letters;