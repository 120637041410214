import "../styles/Input.css";
import { ErrorText } from "./FeedbackText";

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    errorText?: string;
    containerClassName?: string;
    labelClassName?: string;
    inputClassName?: string;
    invalidClassName?: string;
    invalidTextClassName?: string;
}

const Input: React.FunctionComponent<IInput> = (props) => {
    let {
        label,
        errorText,
        containerClassName,
        labelClassName,
        inputClassName,
        invalidClassName: inputInvalidClassName,
        invalidTextClassName,
        ...inputProps
    } = props;

    const getInputClassName = () => {
        let className = "input";

        if (props.inputClassName)
            className = props.inputClassName;

        if (props.errorText) {
            if (props.invalidClassName)
                className += ` ${props.invalidClassName}`;
            else
                className += " input-invalid";
        }

        return className;
    };

    return (
        <div className={props.containerClassName ? props.containerClassName : "input-container"} style={{ width: props.width }}>
            {props.label &&
                <label className={props.labelClassName ? props.labelClassName : "input-label"}>{props.label}</label>
            }
            <input {...inputProps} type={props.type} className={getInputClassName()} />
            {props.errorText &&
                <ErrorText text={props.errorText} className={props.invalidTextClassName ? props.invalidTextClassName : "input-invalid-text"} />
            }
        </div>
    );
}

export type { IInput }

export default Input;