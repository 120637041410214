import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ErrorText } from "../components/FeedbackText";
import Form from "../components/Form";
import { IModelState, IValidationState } from "../components/Form/ManagedForm";
import PasswordInput from "../components/PasswordInput";
import SubmitButton from "../components/SubmitButton";
import { useCommunications } from "../services/Communication";
import { HashPassword } from "../util/password";

interface ILogin {
    Password1: string,
    Password2: string
}

interface ILocationStateProps {
    linkId: string
}

export const PasswordCreation = () => {
    const [credentials, setCredentials] = useState<ILogin>({ Password1: "", Password2: "" })
    const [validationState, setValidationState] = useState<IValidationState>({});
    const navigation = useNavigate();
    let location = useLocation();
    const {postForm} = useCommunications();

    if(!location.state) return <>Invalid</>;
    let { linkId } = location.state as ILocationStateProps;
    if(!linkId) return <>Invalid</>;


    function fillValidation(validationResponse?: IModelState) {
        if (!validationResponse)
            return;

        let validations = {} as IValidationState;
        validationResponse.Validations.forEach(error => {
            validations[error.Key] = error;
        });
        setValidationState(validations);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const hashedPassword = await HashPassword(credentials.Password2);
        postForm<boolean>("/users/changepassword", { LinkUid: linkId, Password: hashedPassword }).then(data => {
            if (data?.Data)
                navigation("/login");
            else
                fillValidation(data?.ModelState)
        });
    }

    const handleFirstPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ Password1: e.target.value }
        }));
    }

    const handleSecondPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ Password2: e.target.value }
        }));
    }

    return <Form onSubmit={handleSubmit} style={{ width: "auto" }} >
        <h1 style={{ alignSelf: "center" }}>Passwort vergeben</h1>
        {validationState["Credantials"] &&
            <ErrorText text={validationState["Credantials"].MessageKey} />
        }
        <PasswordInput label="Neues Passwort" type="Password" value={credentials.Password1} onChange={handleFirstPasswordChanged} errorText={validationState["Password1"]?.MessageKey} />
        <PasswordInput label="Passwort wiederholen" type="Password" value={credentials.Password2} onChange={handleSecondPasswordChanged} errorText={validationState["Password2"]?.MessageKey} />
        <SubmitButton color="accent" disabled={!credentials.Password1 || credentials.Password1 !== credentials.Password2}>Speichern</SubmitButton>
    </Form>
}