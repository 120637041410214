import { ISelectedFields, IField, IVacancyField, IVacancyFieldsInfo, isVacancyFieldsInfo } from "./Vacancies";

export enum ReducerOptions {
    ADD_FIELD = "ADD_FIELD",
    ADD_DOCUMENT_FIELD = "ADD_DOCUMENT_FIELD",
    ADD_PRIVACY_NOTICE_FIELD = "ADD_PRIVACY_NOTICE_FIELD",
    REMOVE_FIELD = "REMOVE_FIELD",
    REMOVE_DOCUMENT_FIELD = "REMOVE_DOCUMENT_FIELD",
    REMOVE_PRIVACY_NOTICE_FIELD = "REMOVE_PRIVACY_NOTICE_FIELD",
    REPLACE_FIELDS = "REPLACE_FIELDS",
    REPLACE_DOCUMENT_FIELDS = "REPLACE_DOCUMENT_FIELDS",
    REPLACE_PRIVACY_NOTICE_FIELDS = "REPLACE_PRIVACY_NOTICE_FIELDS",
    SET_STATE = "SET_STATE",
    ADD_AT_POSITION = "ADD_AT_POSITION",
    ADD_DOCUMENT_AT_POSITION = "ADD_DOCUMENT_AT_POSITION",
    ADD_PRIVACY_NOTICE_AT_POSITION = "ADD_PRIVACY_NOTICE_AT_POSITION",
    CHANGE_NAME = "CHANGE_NAME",
    CHANGE_ACTIVE_STATE = "CHANGE_ACTIVE_STATE"
}
export type Actions = ReducerSetStateAction |
    ReducerAddAction |
    ReducerRemoveAction |
    ReducerReplaceAction |
    ReducerAddAtPositionAction |
    ReducerChangeNameAction |
    ReducerChangeActiveStateAction;

interface ReducerSetStateAction {
    type: ReducerOptions.SET_STATE;
    newState: ISelectedFields | undefined;
}
interface ReducerAddAction {
    type: ReducerOptions.ADD_FIELD | ReducerOptions.ADD_DOCUMENT_FIELD | ReducerOptions.ADD_PRIVACY_NOTICE_FIELD;
    newField: IField;
}
interface ReducerAddAtPositionAction {
    type: ReducerOptions.ADD_AT_POSITION | ReducerOptions.ADD_DOCUMENT_AT_POSITION | ReducerOptions.ADD_PRIVACY_NOTICE_AT_POSITION;
    oldPosition: number;
    newPosition: number;
    newField: IField;
}
interface ReducerRemoveAction {
    type: ReducerOptions.REMOVE_FIELD | ReducerOptions.REMOVE_DOCUMENT_FIELD | ReducerOptions.REMOVE_PRIVACY_NOTICE_FIELD;
    removedFieldId: number;
}
interface ReducerReplaceAction {
    type: ReducerOptions.REPLACE_FIELDS | ReducerOptions.REPLACE_DOCUMENT_FIELDS | ReducerOptions.REPLACE_PRIVACY_NOTICE_FIELDS;
    fields: IVacancyField[];
}
interface ReducerChangeNameAction {
    type: ReducerOptions.CHANGE_NAME,
    name: string
}
interface ReducerChangeActiveStateAction {
    type: ReducerOptions.CHANGE_ACTIVE_STATE,
    active: boolean
}



export const reducer = (state: ISelectedFields | IVacancyFieldsInfo | undefined, action: Actions): ISelectedFields | IVacancyFieldsInfo | undefined => {
    if (action.type === ReducerOptions.SET_STATE)
        return action.newState;

    if (!state)
        throw new Error("State undefined");

    const newField = (field: IField) => {
        let newf: IVacancyField = { FieldId: field.Id, OrderPosition: -1, VacancyNr: -1, Required: false };
        if (isVacancyFieldsInfo(state))
            newf.VacancyNr = state.Vacancy.VacancyId;
        return newf;
    };
    let newFields: IVacancyField[];
    switch (action.type) {
        case ReducerOptions.ADD_FIELD:
            newFields = [...state.Fields, newField(action.newField)];
            return { ...state, Fields: newFields };
        case ReducerOptions.ADD_DOCUMENT_FIELD:
            newFields = [...state.DocumentFields, newField(action.newField)];
            return { ...state, DocumentFields: newFields };
        case ReducerOptions.ADD_PRIVACY_NOTICE_FIELD:
            newFields = [...state.PrivacyNoticeFields, newField(action.newField)];
            return { ...state, PrivacyNoticeFields: newFields };
        case ReducerOptions.REMOVE_FIELD:
            newFields = state.Fields.filter(x => x.FieldId !== action.removedFieldId);
            return { ...state, Fields: newFields };
        case ReducerOptions.REMOVE_DOCUMENT_FIELD:
            newFields = state.DocumentFields.filter(x => x.FieldId !== action.removedFieldId);
            return { ...state, DocumentFields: newFields };
        case ReducerOptions.REMOVE_PRIVACY_NOTICE_FIELD:
            newFields = state.PrivacyNoticeFields.filter(x => x.FieldId !== action.removedFieldId);
            return { ...state, PrivacyNoticeFields: newFields };
        case ReducerOptions.REPLACE_FIELDS:
            return ({ ...state, Fields: action.fields });
        case ReducerOptions.REPLACE_DOCUMENT_FIELDS:
            return ({ ...state, DocumentFields: action.fields });
        case ReducerOptions.REPLACE_PRIVACY_NOTICE_FIELDS:
            return ({ ...state, PrivacyNoticeFields: action.fields });
        case ReducerOptions.ADD_AT_POSITION:
            {
                let prevList = [...state.Fields];
                if (action.oldPosition >= 0) {
                    const removed = prevList.splice(action.oldPosition, 1);
                    if (removed.length > 0)
                        prevList.splice(action.newPosition, 0, removed[0]);
                } else {
                    prevList.splice(action.newPosition, 0, newField(action.newField));
                }
                return { ...state, Fields: prevList };
            }
        case ReducerOptions.ADD_DOCUMENT_AT_POSITION:
            {
                let prevList = [...state.DocumentFields];
                if (action.oldPosition >= 0) {
                    const removed = prevList.splice(action.oldPosition, 1);
                    if (removed.length > 0)
                        prevList.splice(action.newPosition, 0, removed[0]);
                } else {
                    prevList.splice(action.newPosition, 0, newField(action.newField));
                }
                return { ...state, DocumentFields: prevList };
            }
        case ReducerOptions.ADD_PRIVACY_NOTICE_AT_POSITION:
            {
                let prevList = [...state.PrivacyNoticeFields];
                if (action.oldPosition >= 0) {
                    const removed = prevList.splice(action.oldPosition, 1);
                    if (removed.length > 0)
                        prevList.splice(action.newPosition, 0, removed[0]);
                } else {
                    prevList.splice(action.newPosition, 0, newField(action.newField));
                }
                return { ...state, PrivacyNoticeFields: prevList };
            }
        case ReducerOptions.CHANGE_NAME: {
            if (isVacancyFieldsInfo(state))
                return { ...state, CustomVacancyName: action.name };
            else
                return { ...state };
        }
        case ReducerOptions.CHANGE_ACTIVE_STATE: {
            if (isVacancyFieldsInfo(state))
                return { ...state, Vacancy: {...state.Vacancy, Active: action.active}} ;
            else
                return { ...state };
        }
        default:
            break;
    }

    return state;
};
