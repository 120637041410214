import { Editor, EditorChangeEvent, EditorMountEvent, EditorTools, ProseMirror } from "@progress/kendo-react-editor";
import { fieldInfo, FieldInfoHints, fieldInfoStyles, FieldInfoTool } from "./FieldInfoTool";

const {
    Bold,
    Italic,
    Underline,
    ForeColor,
    BackColor,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    SelectAll,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
} = EditorTools;

type HtmlEditorProps = {
    value: string;
    htmlContentChanged?: (html: string) => void;
    hints?: FieldInfoHints
}


export default function HtmlEditor({ value, htmlContentChanged, hints }: HtmlEditorProps) {

    const onChange = (event: EditorChangeEvent) => {
        htmlContentChanged?.call(undefined, event.html);
    }

    const onMount = (event: EditorMountEvent) => {
        const state = event.viewProps.state;
        let plugins = [...state.plugins];
        if (hints)
            plugins.push(fieldInfo(hints));
        const editorDocument = event.dom.ownerDocument;
        const styleElement = editorDocument && editorDocument.querySelector('style');
        if (styleElement) {
            styleElement.appendChild(editorDocument.createTextNode(fieldInfoStyles));
        }
        return new ProseMirror.EditorView(
            {
                mount: event.dom
            },
            {
                ...event.viewProps,
                state: ProseMirror.EditorState.create({
                    doc: state.doc,
                    plugins
                })
            }
        );
    }
    return <>
        <Editor
            onChange={onChange}
            contentStyle={{ height: 630 }}
            tools={[
                FormatBlock,
                [Bold, Italic, Underline],
                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                ForeColor,
                BackColor,
                [OrderedList, UnorderedList, Indent, Outdent],
                FontName,
                [Link, Unlink, InsertImage, ViewHtml],
                [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell],
                [SelectAll],
                (hints ? [FieldInfoTool] : []),
                [Undo, Redo]
            ]}
            onMount={onMount}
            defaultEditMode={"div"}
            value={value}
        />
    </>
}