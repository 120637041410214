import Input, { IInput } from "./Input";

interface IPhoneInput extends IInput {

}

const PhoneInput: React.FunctionComponent<IPhoneInput> = (props) => {
    return (
        <Input {...props} type="tel" pattern="\+*[0-9-\\/ ]*" />
    );
}

export default PhoneInput;