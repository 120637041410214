import Input, { IInput } from "./Input";

interface IPasswordInput extends IInput {
    
}
 
const PasswordInput: React.FunctionComponent<IPasswordInput> = (props) => {
    return (
        <Input {...props} type="password" />
    );
}
 
export default PasswordInput;