import { FaSignOutAlt, FaExpand, FaCompress, FaTrash, FaTimes, FaEdit } from "react-icons/fa";
import Letters from "./Icons/Letters";
import Date from "./Icons/Date";
import Pdf from "./Icons/Pdf";
import Dropdown from "./Icons/Dropdown";
import Numerical from "./Icons/Numerical";
import CheckboxIcon from "./Icons/Checkbox";
import Drag from "./Icons/Drag";

import List from "./Icons/List";
import Settings from "./Icons/Settings";
import Api from "./Icons/Api";
import User from "./Icons/User";
import DesignManager from "./Icons/DesignManager";
import React from "react";
import CopyIcon from "./Icons/CopyIcon";

type Icons = "signOut" | "maximize" | "minimize" | "delete" | "edit" | "close" | "letters" |
    "date" | "pdf" | "dropdown" | "numerical" | "checked-checkbox" | "drag" | "list" | "settings" |
    "api" | "user" | "designmanager" | "copy";

interface IIcon {
    icon: Icons,
    style?: React.CSSProperties | undefined
}

const Icon: React.FunctionComponent<IIcon> = (props) => {
    const getIcon = () => {
        switch (props.icon) {
            case "signOut":
                return <FaSignOutAlt/>
            case "maximize":
                return <FaExpand />
            case "minimize":
                return <FaCompress />
            case "delete":
                return <FaTrash />
            case "edit":
                return <FaEdit />
            case "close":
                return <FaTimes />
            case "letters":
                return <Letters />
            case "date":
                return <Date />
            case "pdf":
                return <Pdf />
            case "dropdown":
                return <Dropdown />
            case "numerical":
                return <Numerical />
            case "checked-checkbox":
                return <CheckboxIcon />
            case "drag":
                return <Drag />
            case "list":
                return <List />
            case "settings":
                return <Settings />
            case "api":
                return <Api />
            case "user":
                return <User />
            case "designmanager":
                return <DesignManager />
            case "copy":
                return <CopyIcon/>
        }
    }

    return (
        <span className="icon" style={{...props.style, display: "flex", alignItems: "center"}}>{getIcon()}</span>
    );
}

export type { Icons }

export default Icon;