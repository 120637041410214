const CopyIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height={"100%"}>
      <g>
        <path d="M12.5,10a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H9.5l3,3Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M9.5,13.5h-7a1,1,0,0,1-1-1v-9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path>
      </g>
    </svg>
  )
}
export default CopyIcon;