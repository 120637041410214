import { useCallback, useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'
import ColorPicker from "../components/ColorPicker";
import { IColors } from "../theme/Themes";
import FormPreview from "./FormPreview";


import '../styles/DesignManager.css';
import { hexToRgb } from "../hooks/ThemeHook";
import Button from "../components/Button";
import { useCommunications } from "../services/Communication";
import Window from "../components/Window";
import Loading from "../components/Loading";
import { useConfig } from "../components/ConfigProvider";

interface IDesignManager {

}

const DesignManager: React.FunctionComponent<IDesignManager> = () => {
    const [colors, setColors] = useState<IColors>();
    const [cssCode, setCssCode] = useState<string>();
    const [isWindowOpen, setIsWindowOpen] = useState<boolean>(false);
    const config = useConfig();
    const {getBase, postBase} = useCommunications();

    const cssUpdates = (newColors: any) => {
        if (!cssCode) return;
        let cssString = cssCode;
        cssString = replace(cssString, '--color-primary', newColors.primary)
        cssString = replace(cssString, '--color-primary-rgb', hexToRgb(newColors.primary))
        cssString = replace(cssString, '--color-primary-foreground', newColors.primaryForeground)
        cssString = replace(cssString, '--color-primary-foreground-rgb', hexToRgb(newColors.primaryForeground))
        cssString = replace(cssString, '--color-secondary', newColors.secondary)
        cssString = replace(cssString, '--color-secondary-rgb', hexToRgb(newColors.secondary))
        cssString = replace(cssString, '--color-border', newColors.border)
        cssString = replace(cssString, '--color-border-rgb', hexToRgb(newColors.border))
        cssString = replace(cssString, '--color-accent', newColors.accent)
        cssString = replace(cssString, '--color-accent-rgb', hexToRgb(newColors.accent))
        cssString = replace(cssString, '--color-accent-foreground', newColors.accentForeground)
        cssString = replace(cssString, '--color-accent-foreground-rgb', hexToRgb(newColors.accentForeground))
        cssString = replace(cssString, '--color-foreground', newColors.foreground)
        cssString = replace(cssString, '--color-foreground-rgb', hexToRgb(newColors.foreground))
        cssString = replace(cssString, '--color-background', newColors.background)
        cssString = replace(cssString, '--color-background-rgb', hexToRgb(newColors.background))
        cssString = replace(cssString, '--color-shadow', newColors.shadow)
        cssString = replace(cssString, '--color-shadow-rgb', hexToRgb(newColors.shadow))
        cssString = replace(cssString, '--color-error', newColors.error)
        cssString = replace(cssString, '--color-error-rgb', hexToRgb(newColors.error))
        cssString = replace(cssString, '--color-error-foreground', newColors.errorForeground)
        cssString = replace(cssString, '--color-error-foreground-rgb', hexToRgb(newColors.errorForeground))
        setCssCode(cssString);
    }

    const setColor = (color: any) => {
        const newColors = { ...colors, ...color };
        setColors(newColors);
        cssUpdates(newColors);
    }

    const handleCssCodeChanged = (value: string) => {
        setCssCode(value);
        setColors(getColorsFromCss(value));
    }

    const getColorsFromCss = useCallback((css: string) => {
        return {
            primary: get(css, '--color-primary'),
            primaryForeground: get(css, '--color-primary-foreground'),
            secondary: get(css, '--color-secondary'),
            border: get(css, '--color-border'),
            accent: get(css, '--color-accent'),
            accentForeground: get(css, '--color-accent-foreground'),
            foreground: get(css, '--color-foreground'),
            background: get(css, '--color-background'),
            shadow: get(css, '--color-shadow'),
            error: get(css, '--color-error'),
            errorForeground: get(css, '--color-error-foreground')
        } as IColors;
    }, []);

    useEffect(() => {
        getBase<string>('/designmanager/get', css => {
            setCssCode(css);
            setColors(getColorsFromCss(css));
        });
    }, [config, getColorsFromCss, getBase]);


    const replace = (str: string, find: string, replace: string) => {
        return str.replaceAll(new RegExp("(?<=" + find + ": )(.*)(?= !important)", "g"), replace);
    }

    const get = (str: string, find: string) => {
        let color = str.match(new RegExp("(?<=" + find + ": )(.*)(?= !important)", "g"));

        if (!color)
            return "#ffffff";

        return color[0];
    }

    const handleSave = () => {
        postBase("/designmanager/post", cssCode, { "Content-Type": "text/css" });
        alert("Style wurde erfolgreich gespeichert!")
    }

    const handleReset = () => {
        if (!window.confirm("Möchten Sie den Style wirklich zurücksetzen?"))
            return;

        getBase<string>("/designmanager/reset", (css) => {
            setCssCode(css);
            setColors(getColorsFromCss(css));
        });
    }

    const SaveResetButtons = () => {
        return (
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                <Button color="accent" onClick={handleSave} style={{ height: "auto", alignSelf: "center" }}>Speichern</Button>
                <Button color="error" onClick={handleReset} style={{ height: "auto", alignSelf: "center" }}>Zurücksetzen</Button>
            </div>
        );
    }

    if (!cssCode || !colors) {
        return (
            <Loading />
        );
    }

    return (
        <div>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", alignItems: "center" }}>
                    <div style={{ display: "flex", flexFlow: "column", gap: "1rem", alignItems: "center" }}>
                        <ColorPicker label="Primary" value={colors.primary} onChange={(e) => setColor({ primary: e })} width="160px" />
                        <ColorPicker label="Primary Foreground" value={colors.primaryForeground} onChange={(e) => setColor({ primaryForeground: e })} width="160px" />
                    </div>
                    <div style={{ display: "flex", flexFlow: "column", gap: "1rem", alignItems: "center" }}>
                        <ColorPicker label="Accent" value={colors.accent} onChange={(e) => setColor({ accent: e })} width="160px" />
                        <ColorPicker label="Accent Foreground" value={colors.accentForeground} onChange={(e) => setColor({ accentForeground: e })} width="160px" />
                    </div>
                    <div style={{ display: "flex", flexFlow: "column", gap: "1rem", alignItems: "center" }}>
                        <ColorPicker label="Error" value={colors.error} onChange={(e) => setColor({ error: e })} width="160px" />
                        <ColorPicker label="Error Foreground" value={colors.errorForeground} onChange={(e) => setColor({ errorForeground: e })} width="160px" />
                    </div>
                    <ColorPicker label="Secondary" value={colors.secondary} onChange={(e) => setColor({ secondary: e })} width="110px" />
                    <ColorPicker label="Border" value={colors.border} onChange={(e) => setColor({ border: e })} width="110px" />
                    <ColorPicker label="Foreground" value={colors.foreground} onChange={(e) => setColor({ foreground: e })} width="110px" />
                    <ColorPicker label="Background" value={colors.background} onChange={(e) => setColor({ background: e })} width="110px" />
                    <ColorPicker label="Shadow" value={colors.shadow} onChange={(e) => setColor({ shadow: e })} width="110px" />
                </div>
                <SaveResetButtons />
            </div>
            <Window toolbarTemplate={isWindowOpen ? <SaveResetButtons /> : undefined} openChanged={(e) => setIsWindowOpen(e)} windowClosedStyle={{ marginTop: "0.5rem" }}>
                <div className="editor-container" style={!isWindowOpen ? { maxHeight: "260px", height: "200px", width: "100%" } : { width: "100%", height: "100%" }}>
                    <Editor
                        value={cssCode}
                        onValueChange={handleCssCodeChanged}
                        highlight={code => highlight(code, languages.css, 'css')}
                        preClassName="pre-css-editor"
                        textareaClassName="css-editor"
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                            marginTop: "1rem"
                        }}
                    />
                </div>
                {isWindowOpen &&
                    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", overflow: "auto" }}>
                        <FormPreview style={cssCode} />
                    </div>
                }
            </Window>

            {!isWindowOpen &&
                <FormPreview style={cssCode} />
            }
        </div>
    );
}

export default DesignManager;