import Button, { IButton } from "./Button";

interface ISubmitButton extends IButton {

}

const SubmitButton: React.FunctionComponent<ISubmitButton> = (props) => {
    return (
        <Button {...props} color="accent" type="submit">{props.children}</Button>
    );
}

export default SubmitButton;