import { useState } from "react";
import Button from "../components/Button";
import NumberInput from "../components/NumberInput";
import PasswordInput from "../components/PasswordInput";
import SubmitButton from "../components/SubmitButton";
import TextInput from "../components/TextInput";
import { useCommunications } from "../services/Communication";
import "../styles/InitialConfig.css";

type InitialConfig = {
    License: { Encoded: string },
    Db: {
        ServerAddress: string,
        Port: number,
        Schema: string,
        UserName: string,
        Password: string
    },
    Admin: {
        UserName: string,
        Password: string
    }
}

type DbConnectionResult = {
    Success: boolean,
    Error: string
}

const InitilaConfig = ({ checkInstall }: { checkInstall: () => void }) => {
    const { post } = useCommunications();
    const [adminPassword2, setAdminPassword2] = useState("");
    const [config, setConfig] = useState<InitialConfig>({
        License: { Encoded: "" },
        Db: {
            ServerAddress: "",
            Port: 0,
            Schema: "",
            UserName: "",
            Password: ""
        },
        Admin: {
            UserName: "",
            Password: ""
        }
    });

    const init = async () => {
        const res = await post<boolean>("/config/saveinitialconfig", config);
        if (res)
            checkInstall.call(this);
    }

    const testDbConnection = async () => {

        const res = await post<DbConnectionResult>("/config/testdbconnection", config.Db);
        if (!res)
            return;
        if (res.Success)
            alert("Erfolgreich")
        else
            alert(res.Error);

    }

    const testLicense = async () => {
        const res = await post<DbConnectionResult>("/config/testlicenseconnection", config.License );
        if (!res)
            return;
        if (res.Success)
            alert("Erfolgreich")
        else
            alert(res.Error);
    }

    const checkValidity = () => {
        let valid = (config.Admin.UserName && config.Admin.Password) || (!config.Admin.UserName && !config.Admin.Password);
        return valid && adminPassword2 === config.Admin.Password;
    }

    const valid = checkValidity();

    return <div className="initialPage">
        <div className="initialConfig">
            <div className="configBlock">
                <h4>Datenbank</h4>
                <TextInput value={config.Db.ServerAddress} label="Serveradresse" onChange={element => setConfig(c => ({ ...c, Db: { ...c.Db, ServerAddress: element.target.value } }))} />
                <NumberInput value={config.Db.Port} label="Port" onChange={element => setConfig(c => ({ ...c, Db: { ...c.Db, Port: +element.target.value } }))} />
                <TextInput value={config.Db.Schema} label="Schema" onChange={element => setConfig(c => ({ ...c, Db: { ...c.Db, Schema: element.target.value } }))} />
                <TextInput value={config.Db.UserName} label="Benutzername" onChange={element => setConfig(c => ({ ...c, Db: { ...c.Db, UserName: element.target.value } }))} />
                <PasswordInput value={config.Db.Password} label="Passwort" onChange={element => setConfig(c => ({ ...c, Db: { ...c.Db, Password: element.target.value } }))} />
                <Button onClick={() => testDbConnection()} >Verbindung testen</Button>

            </div>
            <div className="configBlock">
                <h4>Admin Benutzer</h4>
                <TextInput value={config.Admin.UserName} label="Benutzername" onChange={element => setConfig(c => ({ ...c, Admin: { ...c.Admin, UserName: element.target.value } }))} />
                <PasswordInput value={config.Admin.Password} label="Passwort" onChange={element => setConfig(c => ({ ...c, Admin: { ...c.Admin, Password: element.target.value } }))} />
                <PasswordInput value={adminPassword2} label="Passwort wiederholen" onChange={element => setAdminPassword2(element.target.value)} />

            </div>
        </div>
        <div className="configBlock">
            <h4>Lizenz</h4>
            <TextInput value={config.License.Encoded} label="Lizenz" onChange={element => setConfig(c => ({ ...c, License: {Encoded: element.target.value }}))} />
            <Button onClick={() => testLicense()} >Verbindung testen</Button>
        </div>
        <SubmitButton onClick={() => init()} disabled={!valid}>Speichern</SubmitButton>
    </div>

}

export default InitilaConfig;