import Input, { IInput } from "./Input";

interface IFileInput extends IInput {

}

const FileInput: React.FunctionComponent<IFileInput> = (props) => {
    return (
        <Input {...props} type="file" />
    );
}

export default FileInput;