import { useState } from "react";
import { AccentedText } from "../components/FeedbackText";
import Form from "../components/Form";
import { IValidationState, IModelState } from "../components/Form/ManagedForm";
import SubmitButton from "../components/SubmitButton";
import TextInput from "../components/TextInput";
import { useCommunications } from "../services/Communication";


interface IPasswordReset {
    EMail: string,
    UserName: string
}

export default function PasswordReset() {
    const [credentials, setCredentials] = useState<IPasswordReset>({ EMail: "", UserName: "" })
    const [validationState, setValidationState] = useState<IValidationState>({});
    const { postForm } = useCommunications();

    function fillValidation(validationResponse?: IModelState) {
        if (!validationResponse)
            return;

        let validations = {} as IValidationState;
        validationResponse.Validations.forEach(error => {
            validations[error.Key] = error;
        });
        setValidationState(validations);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postForm<boolean>("/users/resetpassword", { UserName: credentials.UserName, EMail: credentials.EMail } as IPasswordReset).then(data => {


            if (data?.Data)
                fillValidation({IsValid: true, Validations:[{Key: "Credentials", MessageKey: "Wenn die Eingabe übereinstimmt, bekommen Sie in den nächsten Minuten eine Mail."}]})
            else
                fillValidation(data?.ModelState)
        });
    }

    const handleUserNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ UserName: e.target.value }
        }));
    }

    const handleMailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(credentials => ({
            ...credentials,
            ...{ EMail: e.target.value }
        }));
    }

    return (
        <div className="login-container">
            <Form onSubmit={handleSubmit} style={{ width: "auto" }} >
                <h1 style={{ alignSelf: "center" }}>Passwort zurücksetzen</h1>
                {validationState["Credentials"] &&
                    <AccentedText text={validationState["Credentials"].MessageKey} />
                }
                <TextInput label="Benutzername" value={credentials.UserName} onChange={handleUserNameChanged} errorText={validationState["UserName"]?.MessageKey} />
                <TextInput label="E-Mail" value={credentials.EMail} onChange={handleMailChanged} errorText={validationState["EMail"]?.MessageKey} />
                <SubmitButton color="accent">Link Anfragen</SubmitButton>
            </Form>
        </div>
    );
}