
import { ReactElement } from "react";
import DateRangeInput, { IDateRangeInput } from "./DateRangeInput";
import { IFormValidation } from "./FormValidation";
import FormMultiSelectInput, { IFormMultiSelectInput } from "./FormMultiSelectInput";
import FormTextInput, { FormPasswordInput, IFormTextInput } from "./FormTextInput";
import FormFileInput, { IFormFileInput } from "./FormFileInput";
import FormDateInput, { IFormDateInput } from "./FormDateInput";
import FormEmailInput, { IFormEmailInput } from "./FormEmailInput";
import FormNumberInput, { IFormNumberInput } from "./FormNumberInput";
import FormCheckbox, { IFormCheckboxInput } from "./FormCheckBox";
import FormDropDown, { IFormDropDownIput } from "./FormDropDown";
import DisplayText from "./DisplayText";
import FormPhoneInput, { IFormPhoneInput } from "./FormPhoneInput";

interface IFormInput<T = any> {
    Discriminator: FORM_TYPE;
    Label: string;
    Id: Extract<keyof T, string | number>;
    onChange?: any;
    Value?: any;
    Default?: any;
    Hidden?: boolean;
    Validation?: IFormValidation;
}


enum FORM_TYPE {
    MultiSelect = "MultiSelectInput",
    CheckBox = "CheckBoxInput",
    Text = "TextInput",
    Password = "PasswordInput",
    DropDownInput = "DropDownInput",
    Number = "NumberInput",
    DateRange = "DateRangeInput",
    DateInput = "DateInput",
    File = "FileInput",
    DateTime = "DateTimeInput",
    Email = "EmailInput",
    DisplayText = "DisplayText",
    Phone = "PhoneInput"
}



const FormInput: React.FunctionComponent<IFormInput<any>> = (props) => {
    function evaluateComponent(): ReactElement {
        switch (props.Discriminator) {
            case FORM_TYPE.MultiSelect:
                return <FormMultiSelectInput {...props as IFormMultiSelectInput<any>} />
            case FORM_TYPE.Text:
                return <FormTextInput {...props as IFormTextInput} />
            case FORM_TYPE.Password:
                return <FormPasswordInput {...props as IFormTextInput}/>
            case FORM_TYPE.Number:
                return <FormNumberInput {...props as IFormNumberInput} />
            case FORM_TYPE.Email:
                return <FormEmailInput {...props as IFormEmailInput} />
            case FORM_TYPE.Phone:
                return <FormPhoneInput {...props as IFormPhoneInput} />
            case FORM_TYPE.DateRange:
                return <DateRangeInput {...props as IDateRangeInput} />
            case FORM_TYPE.DateInput:
                return <FormDateInput {...props as IFormDateInput} />
            case FORM_TYPE.File:
                return <FormFileInput {...props as IFormFileInput} />
            case FORM_TYPE.CheckBox:
                return <FormCheckbox {...props as IFormCheckboxInput} />
            case FORM_TYPE.DropDownInput:
                return <FormDropDown {...props as IFormDropDownIput} />
            case FORM_TYPE.DisplayText:
                return <DisplayText {...props as IFormInput}></DisplayText>
            default:
                return <>{props.Discriminator}</>
        }
    }
    return (<div className="form-input-container">
        {evaluateComponent()}
    </div>
    );
}

export type { IFormInput }
export { FORM_TYPE }
export default FormInput;