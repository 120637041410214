import { useState } from "react";
import Button from "./Button";
import { ErrorText } from "./FeedbackText";
import Form from "./Form";
import FormInput, { IFormInput } from "./Form/FormInput";
import { IValidationState } from "./Form/ManagedForm";
import SubmitButton from "./SubmitButton";

interface IDataForm<T> {
  Id: string,
  dataItem: T
  onSubmit: (dataItem: T) => boolean | void,
  onAbort: () => void,
  Inputs: IFormInput<T>[],
  validationState: IValidationState,
  onInputChange: (formInput: T) => void,
  saveText?: string
}


function DataForm<T>(props: IDataForm<T>) {
  let[orginal, setOriginal] = useState({ ...props.dataItem });
  let data = { ...props.dataItem };

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    let result = props.onSubmit(data as T);
    if (result === undefined || result === true) {
      setOriginal(data);
    }
  }

  function onInputChange(formInput: IFormInput<T>, value: any) {
    let formData = { ...data };
    if (formInput.Default === undefined && value === "")
      delete formData[formInput.Id]
    else
      formData[formInput.Id] = value;
    props.onInputChange(formData);
  }

  function hasDataChanged() {
    for (let key in data) {
      if (data[key] !== orginal[key])
        return true;
    }

    for (let key in orginal) {
      if (data[key] !== orginal[key])
        return true;
    }
    return false;
  }

  var valid = Object.keys(props.validationState).length === 0 ?? true;

  return (
    <Form
      id={props.Id}
      onSubmit={handleSubmit}>
      <ErrorText text={props.validationState[props.Id]?.MessageKey} />
      {props.Inputs.map((input) => {
        return <FormInput key={input.Id} {...input} Value={data[input.Id]} Validation={props.validationState[input.Id]} onChange={onInputChange} />
      })}
      <div style={{ display: "flex", justifyContent: "" }}>
        <SubmitButton disabled={!hasDataChanged() || !valid}>{props.saveText ? props.saveText : "Speichern"}</SubmitButton>
        <Button color="error" onClick={props.onAbort}>Abbrechen</Button>
      </div>
    </Form>
  );
}

export type { IDataForm }

export default DataForm;