import { ReactElement, useState } from "react"

import "../styles/Dropdown.css";
import { ErrorText } from "./FeedbackText";

interface IDropdownValue {
    Value: string
};

interface IDropdownProperties<T> {
    id?: string;
    label?: string;
    data: T[];
    value: T | undefined;
    onItemClicked: (item: T) => void;
    itemTemplate: (item: T | undefined) => ReactElement;
    errorText?: string;
    containerClassName?: string;
    labelClassName?: string;
    dropdownClassName?: string;
    dropdownPickerClassName?: string;
    dropdownValueClassName?: string;
    dropdownIconClassName?: string;
    dropdownIconFlippedClassName?: string;
    dropdownContentClassName?: string;
    dropdownContentOpenClassName?: string;
    dropdownItemClassName?: string;
    dropdownItemSelectedClassName?: string;
    dropdownInvalidClassName?: string;
    dropdownInvalidTextClassName?: string;
};

const Dropdown = <T extends IDropdownValue>(props: IDropdownProperties<T>) => {
    const [selectedItem, setSelectedItem] = useState(props.value);
    const [isOpen, setOpen] = useState(false);

    const getInputClassName = () => {
        let className = "dropdown";

        if (props.dropdownClassName)
            className = props.dropdownClassName;

        if (props.errorText) {
            if (props.dropdownInvalidClassName)
                className += ` ${props.dropdownInvalidClassName}`;
            else
                className += " dropdown-invalid";
        }

        return className;
    };

    return (
        <div className={props.containerClassName ? props.containerClassName : "dropdown-container"}>
            {props.label && <label className={props.labelClassName ? props.labelClassName : "dropdown-label"}>{props.label}</label>}
            <div className={getInputClassName()} tabIndex={0} onBlur={close} onClick={onClicked}>
                <div className={props.dropdownPickerClassName ? props.dropdownPickerClassName : "dropdown-picker"}>
                    <div className={props.dropdownValueClassName ? props.dropdownValueClassName : "dropdown-value"}>
                        {props.itemTemplate(selectedItem)}
                    </div>
                    <span className={`k-icon k-i-arrow-chevron-down ${props.dropdownIconClassName ? props.dropdownIconClassName : "dropdown-icon"}
                    ${isOpen ? (props.dropdownIconFlippedClassName ? props.dropdownIconFlippedClassName : "dropdown-icon-flipped") : ""}`}></span>
                </div>
                <div className={`${props.dropdownContentClassName ? props.dropdownContentClassName : "dropdown-content"}
                ${isOpen ? (props.dropdownContentOpenClassName ? props.dropdownContentOpenClassName : "dropdown-content-open") : ""}`} >
                    {props.data.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`${props.dropdownItemClassName ? props.dropdownItemClassName : "dropdown-item"}
                                ${item.Value === selectedItem?.Value ? (props.dropdownItemSelectedClassName ? props.dropdownItemSelectedClassName : "dropdown-item-selected") : ""}`}
                                onClick={() => itemClicked(item)}>
                                {props.itemTemplate(item)}
                            </div>
                        );
                    })}
                </div>
            </div>
            {props.errorText &&
                <ErrorText text={props.errorText} className={props.dropdownInvalidTextClassName ? props.dropdownInvalidTextClassName : "dropdown-invalid-text"} />
            }
        </div>
    )

    function close() {
        setOpen(false);
    }

    function itemClicked(item: T) {
        setSelectedItem(item)
        props.onItemClicked(item);
        setOpen(false);
    }

    function onClicked() {
        setOpen(!isOpen);
    }
}

export default Dropdown

export type { IDropdownValue }
