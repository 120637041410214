import { useCallback, useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import HtmlEditor from "../components/Editor/HtmlEditor";
import { Flex } from "../components/Layout/Flex";
import SubmitButton from "../components/SubmitButton";
import { useCommunications } from "../services/Communication";
import { KeyValuePair } from "../types/KeyValuePair";
import { IField } from "./Vacancies";

export function MailEditor() {
    const [mailTypes, setMailTypes] = useState<KeyValuePair[]>([]);
    const [selectedMailType, setSelectedMailType] = useState<KeyValuePair>();
    const [html, setHtml] = useState<string>("");
    const { get, getBase, post } = useCommunications();
    const [possibleFields, setPossibleFields] = useState<Array<IField>>([]);

    const onSelection = useCallback((selection: KeyValuePair) => {
        if (!selection)
            return;
        getBase<string>("/MailEditor/GetHtml?type=" + selection.Key,
            h => {
                setSelectedMailType(selection);
                setHtml(h);
            });
    }, [getBase]);

    useEffect(() => {
        get<KeyValuePair[]>("/MailEditor/GetMailTemplateTypes", t => { setMailTypes(t); onSelection(t[0]); });
        get<Array<IField>>("/FormFields/PossibleFields", data => {
            setPossibleFields(data)
        });
    }, [get, onSelection]);

    const save = () => {
        post<boolean>("/MailEditor/SaveTemplate", { Type: selectedMailType?.Key, Template: html })
            .then(result => {
                if(result){
                    alert(selectedMailType?.Value + " erfolgreich gespeichert.");
                }
                else{
                    alert("Etwas ist schief gegangen");
                }
            });
    };

    if (!selectedMailType || !possibleFields)
        return <></>

    return <>
        <h3>E-Mail Vorlagen</h3>
        <div className="form">
            <Flex direction="row" grow={[1, 0]}>
                <Dropdown data={mailTypes} value={selectedMailType} itemTemplate={x => <>{x?.Value}</>} onItemClicked={x => onSelection(x)} />
                <SubmitButton onClick={save}>Speichern</SubmitButton>
            </Flex>
            <HtmlEditor value={html} key={selectedMailType.Key} htmlContentChanged={setHtml}
                hints={[{
                    fields: possibleFields.map(x => ({ Key: x.Id.toString(), Value: x.Name })),
                    isEqual: (match, key) => key === match.replace("{", "").replace("}", ""),
                    regx: "{[0-9]+}"
                }]}
            />
        </div>
    </>;
}
