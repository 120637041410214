import { useRef, useState, useLayoutEffect } from "react";
import { createBrowserHistory, BrowserHistory } from "history";
import { Router, BrowserRouterProps } from "react-router-dom";

export const customHistory = createBrowserHistory();

const CustomRouter: React.FunctionComponent<BrowserRouterProps> = (props) => {
    let historyRef = useRef<BrowserHistory>();
    if (historyRef.current == null) {
        historyRef.current = customHistory;
    }

    let history = historyRef.current;
    let [state, setState] = useState({
        action: history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={props.basename}
            children={props.children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
}

export default CustomRouter;