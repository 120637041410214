import React, { useCallback, useContext, useEffect, useState } from "react";
import { useCommunications } from "../services/Communication";
import InitilaConfig from "../views/InitialConfig";

const UNLOADEDCONFIG: IConfig = {
    serverUrl: "none"
}

interface IConfig {
    serverUrl: string
}

const ConfigContext = React.createContext<IConfig>(UNLOADEDCONFIG)

const hasServerUrl = (cfg: any): cfg is { serverUrl: string } => {
    return cfg["serverUrl"] !== undefined;
}

export function ConfigProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const [config, setConfig] = useState<IConfig>(UNLOADEDCONFIG);

    useEffect(() => {
        fetch("/config/config.json")
            .then(response => response.json())
            .then(result => {
                if (hasServerUrl(result)) {
                    setConfig({ serverUrl: result.serverUrl })
                    return result;
                }
                else
                    throw new Error("No Valid Server config")
            })
    }, [])

    return <ConfigContext.Provider value={config} >
        {config !== UNLOADEDCONFIG && <InitialInstallation>{children}</InitialInstallation>}
    </ConfigContext.Provider>
}

function InitialInstallation({ children }: { children: JSX.Element | JSX.Element[] }) {
    const [initial, setInitial] = useState<boolean | undefined>(undefined);
    const { get } = useCommunications();

    const checkInstall = useCallback(() => {
        get<boolean>("/config/isinitialinstallation", init => setInitial(init))
    }, [get])

    useEffect(() => checkInstall(), [checkInstall])
    if (initial === undefined)
        return <span>...</span>

    return <>{(initial ? <InitilaConfig checkInstall={checkInstall} /> : children)}</>
}

export function useConfig() { return useContext(ConfigContext) }

