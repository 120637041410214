import { useEffect, useState } from "react";
import { useCommunications } from "../../services/Communication";
import Dropdown, { IDropdownValue } from "../Dropdown";
import { IFormInput } from "./FormInput";

interface IFormDropDownIput extends IFormInput {
    Type: string
}

interface IDropDownItem extends IDropdownValue {
    Value: string,
    DisplayText: string
}

const FormDropDown: React.FunctionComponent<IFormDropDownIput> = (props) => {
    const [items, setItems] = useState<IDropDownItem[]>([]);
    const { get } = useCommunications();

    const onChange = (value: string) => {
        props.onChange(props, value);
    };

    const itemTemplate = (item: IDropDownItem | undefined) => {
        return <>
            {item?.DisplayText}
        </>
    };

    function onItemClicked(item: IDropDownItem) {
        onChange(item.Value);
    };

    useEffect(() => {
        get<IDropDownItem[]>('/Form/DropDownValues?type=' + props.Type, response => {
            setItems(response);
        })
    }, [props.Type, get])

    if (items.length === 0) return <></>;
    return (
        <Dropdown key={props.Id} value={items.find(x => x.Value === props.Value)} data={items} label={props.Label} itemTemplate={itemTemplate} onItemClicked={onItemClicked} errorText={props.Validation?.MessageKey} />
    );
}

export type { IFormDropDownIput }

export default FormDropDown;