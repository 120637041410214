import { useEffect, useState } from "react";
import DataGrid from "../components/DataGrid";
import { FORM_TYPE } from "../components/Form/FormInput";
import { IFormMultiSelectInput } from "../components/Form/FormMultiSelectInput";
import { IFormResult } from "../components/Form/ManagedForm";
import { useCommunications } from "../services/Communication";


interface IUser {
    UserId: string,
    UserName: string,
    EMail: string,
    Active: boolean,
    MasterAdmin: boolean,
    Permissions: number[]
}

interface Permission {
    Name: string,
    Id: number
}

export default function Users() {
    const [permissionGroups, setPermissionGroups] = useState<Permission[]>([{ Id: -1, Name: "...loading" }])
    const {get, post, postForm} = useCommunications();
    const addUser = async (user: IUser) => {
        let response = await postForm<IUser>("/users/create", user);
        return response as IFormResult<IUser>;
    }

    const editUser = async (user: IUser) => {
        let response = await postForm<IUser>("/users/edit", user);
        return response as IFormResult<IUser>;
    }

    const deleteUser = (user: IUser) => {
        if (window.confirm("Wollen sie den Benutzer wirklich löschen?")) {
            post<IUser>("/users/Delete", user);
            return true;
        }
        return false;
    }

    useEffect(() => {
        get<Permission[]>("/users/getpermissiongroups", setPermissionGroups)
    }, [get])


    return (<>
        <DataGrid<IUser> DataSource="/users/users" Discriminator="" RowDefinition={{
            DataItemKey: "UserId",
            ColumnDefinitions: [
                { Field: "UserName", Title: "Benutzer" },
                { Field: "EMail", Title: "E-Mail" },
                { Field: "Active", Title: "Aktiv" }
            ],
            ButtonDefinitions: {
                onDelete: deleteUser,
                onDeleteText: "Löschen",
                onEditText: "Bearbeiten",
                onEdit: editUser,
                onCreate: addUser,
                editForm: [
                    { Id: "UserName", Label: "User", Discriminator: FORM_TYPE.Text },
                    { Id: "EMail", Label: "E-Mail", Discriminator: FORM_TYPE.Email },
                    { Id: "Active", Label: "Activ", Discriminator: FORM_TYPE.CheckBox },
                    { Id: "Permissions", Label: "Berechtigungen", Discriminator: FORM_TYPE.MultiSelect, Items: permissionGroups.map(x => ({ Text: x.Name, Value: x.Id })) } as IFormMultiSelectInput<IUser>
                ]
            }
        }} />


    </>)
}