const Dropdown: React.FunctionComponent = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5px" height={20}>
            <g>
                <circle cx="3" cy="3" r="2.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></circle>
                <circle cx="3" cy="12" r="2.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></circle>
                <circle cx="3" cy="21" r="2.25" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></circle>
                <line x1="8.25" y1="3" x2="23.25" y2="3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="8.25" y1="12" x2="23.25" y2="12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="8.25" y1="21" x2="23.25" y2="21" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></line>
            </g>
        </svg>
    );
}

export default Dropdown;