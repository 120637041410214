import { useSessionStorage } from "../hooks/SessionStorageHook";
import Popup from "./Popup";

const NoValidLicense: React.FunctionComponent = () => {
    const [showLicenseInformation, setShowLicenseInformation] = useSessionStorage("showLicenseInformation", true);

    if(!showLicenseInformation) {
        return <></>;
    }
    else {
        return (
            <Popup open={true} width='500px' height='200px' openChanged={(open) => {
                setShowLicenseInformation(open);
            }}>
                <p>Ihre Lizenz ist abgelaufen.</p>
                <p>Bitte melden Sie sich bei der adata Software GmbH.</p>
            </Popup>
        );
    }
    
}

export default NoValidLicense;