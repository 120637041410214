import { useEffect, useState } from "react";
import { IFormInput } from "./FormInput";

interface IDateRangeInput extends IFormInput {
}

interface IDateRange {
    Start: Date,
    End: Date
}

const DateRangeInput: React.FunctionComponent<IDateRangeInput> = (props) => {
    const [range, setRange] = useState<IDateRange>(props.Value as IDateRange ?? {Start: new Date(),End: new Date()});

    const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRange({ ...range, Start: event.target.valueAsDate!});
    }

    const onChangeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRange({ ...range, End: event.target.valueAsDate!});
    }

    useEffect(() => {
        props.onChange(props, range);
    }, [range, props])

    return (<div className="form-input">
        <div className="form-label">
            <p>Start</p>
        </div>
        <input type={"date"} onChange={onChangeStart} defaultValue={props.Value} />
        <div className="form-label">
            <p className="form-label">End</p>
        </div>
        <input type={"date"} onChange={onChangeEnd} defaultValue={props.Value} />
    </div>);
}

export type { IDateRangeInput }

export default DateRangeInput;