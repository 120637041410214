import Input, { IInput } from "./Input";

interface IEmailInput extends IInput {
    
}
 
const EmailInput: React.FunctionComponent<IEmailInput> = (props) => {
    return (
        <Input {...props} type="email" />
    );
}
 
export default EmailInput;